import React, { useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../slices/thunk";

import { loginuser, resetLoginMsgFlag, socialLogin } from "slices/auth/thunk";

import { createSelector } from "reselect";

import ImageAuth from "../../assets/images/bg_auth.png";
import Logo from "../../assets/images/logo-auth.png";

const ForgetPasswordPage = (props) => {
  //meta title
  document.title = "Resetar Senha | Instituto Global ESG";

  const dispatch = useDispatch<any>();

  const selectProperties = createSelector(
    (state: any) => state.Login,
    (error) => error
  );

  const { error } = useSelector(selectProperties);

  // Form validation
  const validation: any = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("E-mail inválido")
        .required("Por favor, digite o seu email."),
      password: Yup.string().required("Por favor, digite a sua senha"),
    }),
    onSubmit: (values: any) => {
      dispatch(loginuser(values, props.router.navigate));
    },
  });

  const signIn = (type: any) => {
    dispatch(socialLogin(type, props.router.navigate));
  };

  const socialResponse = (type: any) => {
    signIn(type);
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginMsgFlag());
      }, 3000);
    }
  }, [dispatch, error]);

  return (
    <React.Fragment>
      <div className="account-pages">
        <Row>
          <Col className="container-left-auth d-none d-lg-flex">
            <img src={ImageAuth} alt="Duas pessoas dando risada" />

            <div className="details-auth">
              <h1>Instituto Global.</h1>
              <p>
                Um mundo de soluções ESG.
              </p>
            </div>
          </Col>
          <Col xl={3} className="container-right-auth">
            <div className="w-100 h-100">
              <Row>
                <div className="text-primary p-4">
                  <img src={Logo} alt="" />
                </div>
              </Row>

              <Row className="p-3 form-auth">
                <div>
                  <div>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Reset Password</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Reset Password"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>

                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Reset Password"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block btn-auth"
                          type="submit"
                        >
                          Resetar Senha
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>

                <div className="mt-3 text-center reset-password-success d-none">
                  <div>
                    <i className="bx bx-mail-send" />
                  </div>

                  <p className="title">Senha redefinida com sucesso.</p>
                  <p className="details">
                    Verifique seu email e siga as instruções para finalizar a
                    alteração da senha.
                  </p>
                  <button className="btn button-back-to-login">
                    <i className="bx bx-chevron-left" />
                    Voltar para Login
                  </button>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgetPasswordPage);
