import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";

import Breadcrumb from "Components/Common/Breadcrumb";
import TotalBalanceCardPartnerCompany from "Components/partnerCompany/dashboard/totalBalanceCard";
import Activity from "Components/finalClient/home/activity";

import EnergyVoucherBonuses from "Components/partnerCompany/dashboard/EnergyVoucherBonuses";

import StackedColumnChart from "Components/finalClient/historic/graphic";
import { partnerDashboardSelector } from "slices/partner/dashboard/reducer";

import classNames from "classnames";

import "./dashboard.styles.scss";
import Cashback from "Components/partnerCompany/dashboard/Cashback";
import { useAppDispatch, useAppSelector } from "slices";
import { fetchData } from "slices/partner/dashboard/thunk";

const yearData = [
  // {
  //   name: "Series A",
  //   data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48],
  // },
  {
    name: "Contas Pagas",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2],
  },
  {
    name: "Vales",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3],
  },
];

const monthData = [
  {
    name: "Series A",
    data: [34, 55, 21, 77, 32, 63, 86, 42, 34, 18, 16, 41],
  },
  {
    name: "Series B",
    data: [10, 63, 40, 80, 52, 41, 11, 32, 30, 86, 44, 33],
  },
  {
    name: "Series C",
    data: [11, 17, 15, 85, 21, 14, 80, 58, 17, 12, 20, 18],
  },
];

const weekData = [
  {
    name: "Series A",
    data: [14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68],
  },
  {
    name: "Series B",
    data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
  },
  {
    name: "Series C",
    data: [11, 17, 15, 15, 34, 55, 21, 18, 17, 12, 20, 18],
  },
];

const DashboardPartnerCompany = () => {
  document.title = "Início | Instituto Global ESG";
  const dispatch: any = useAppDispatch();
  const { balance, myVouchers, loading, totalCounters, simpleTransactionHistory } = useAppSelector(partnerDashboardSelector)
console.log('dash data', totalCounters)
  const reports = [
    {
      title: "Total de Vales",
      iconClass: "bx-copy-alt",
      description: totalCounters.totalBillsPaid,
    },
    {
      title: "Economia Média",
      iconClass: "bx-archive-in",
      description: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(totalCounters.totalBillsSaved || '0')),
    },
    // {
    //   title: "Marcas Parceiras",
    //   iconClass: "bx-purchase-tag-alt",
    //   description: "4.005,02",
    // },
  ];

  const [periodData, setPeriodData] = useState<any>([]);
  const [periodType, setPeriodType] = useState<string>("yearly");

  useEffect(() => {
    if (periodType === "weekly") {
      setPeriodData(weekData);
    }
    if (periodType === "monthly") {
      setPeriodData(monthData);
    }
    if (periodType === "yearly") {
      setPeriodData(yearData);
    }
  }, [periodType]);

  const onChangeChartPeriod = (pType: any) => {
    setPeriodType(pType);
  };

  useEffect(() => {
    dispatch(fetchData(null))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Início" breadcrumbItem="Painel" />

          <div className="d-flex gap-5 flex-column flex-lg-row">
            <div className="col col-lg-4">
              <Row>
                <Col>
                  <TotalBalanceCardPartnerCompany data={balance} />
                </Col>
              </Row>

              <Row className="d-none d-lg-flex mt-5">
                <Col>
                  <Activity data={simpleTransactionHistory} />
                </Col>
              </Row>
            </div>

            <Col>
              <Row>
                {/* Reports Render */}
                {(reports || []).map((report: any, key: number) => (
                  <Col xl={6} key={"_col_" + key}>
                    <Card className="mini-stats-wid card-dashboard">
                      <CardBody className="d-flex">
                        <div className="flex-grow-1">
                          <p className="title-card">{report.title}</p>
                          <h4 className="card-description">
                            {/* <span>R$</span> */}
                            {report.description}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon icon-card">
                          <span className="avatar-title rounded-circle">
                            <i
                              className={
                                "bx " + report.iconClass + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Row>
                {/* <Col xl={3}>
                  <EnergyVoucherBonuses />
                </Col> */}

                <Col>
                  <Card>
                    <CardBody>
                      <div className="d-sm-flex flex-wrap">
                        <div className="ms-auto">
                          {/* <Nav pills>
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classNames(
                                  { active: periodType === "weekly" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  onChangeChartPeriod("weekly");
                                }}
                                id="one_month"
                              >
                                Week
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classNames(
                                  { active: periodType === "monthly" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  onChangeChartPeriod("monthly");
                                }}
                                id="one_month"
                              >
                                {" "}
                                Month{" "}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classNames(
                                  { active: periodType === "yearly" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  onChangeChartPeriod("yearly");
                                }}
                                id="one_month"
                              >
                                {" "}
                                Year{" "}
                              </NavLink>
                            </NavItem>
                          </Nav> */}
                        </div>
                      </div>
                      {/* <div className="clearfix"></div> */}
                      <StackedColumnChart
                        periodData={periodData}
                        dataColors='["#6921A0", "#34C38F", "#8358DA"]'
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardPartnerCompany;
