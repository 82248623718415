import React from "react";

import { Col, Container, Row } from "reactstrap";

import Breadcrumb from "Components/Common/Breadcrumb";

import "./termsOfUse.styles.scss";

const TermsOfUse = () => {
  document.title = "Termos de Uso | Instituto Global ESG";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Início" breadcrumbItem="Termos de Uso" />

          <Row>
            <Col xl={8} className="support-page">
              <div className="support-content">
                <h5 className="support-content_title">Description</h5>
                <p className="support-content_description">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                  massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                  sapien.
                </p>
                <h5>Responsibilities:</h5>
                <ul>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                </ul>
                <h5>Requirements:</h5>
                <ul>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                </ul>
                <h5>Qualification:</h5>
                <ul>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                  <li>
                    Meeting with the design team to discuss the needs of the
                    company.
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={4}>
              <h5>Navegação</h5>
              <ul>
                <li>Termos de Uso</li>
                <li>Política de Privacidade</li>
              </ul>

              <div className="d-flex flex-column gap-3">
                <button className="btn btn-download-pdf">
                  <i className="mdi mdi-file-download-outline"></i>
                  Baixar PDF
                </button>
                <button className="btn btn-share">
                  <i className="mdi mdi-share-variant-outline"></i>
                  Compartilhar
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TermsOfUse;
