import React, { useEffect, useState } from "react";
import { ReCaptcha } from "react-recaptcha-v3";

import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Form,
  Input,
  Label,
  FormFeedback,
  Button,
  Modal,
  Spinner,
} from "reactstrap";

import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

import { useAppSelector } from "slices";
import { authSelector } from "slices/auth/reducer";

import ImageAuth from "../../assets/images/bg_auth.png";
import Logo from "../../assets/images/logo-auth.png";

import { loginuser, resetLoginMsgFlag, socialLogin } from "slices/auth/thunk";

import withRouter from "Components/Common/withRouter";

import "./auth.styles.scss";
const Login = (props: any) => {
  document.title = "Login | Instituto Global ESG";

  const dispatch: any = useDispatch();
  const { error, loading } = useAppSelector(authSelector);

  const [reCaptchaToken, setReCaptchaToken] = useState("");

  const [modalError, setModalError] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleModalError() {
    setModalError(!modalError);
    removeBodyCss();
  }

  const validation: any = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("E-mail inválido")
        .required("Por favor, digite o seu email."),
      password: Yup.string().required("Por favor, digite sua senha."),
    }),
    onSubmit: (values: any) => {
      values.reCaptchaToken = reCaptchaToken;
      dispatch(loginuser(values, props.router.navigate));
    },
  });

  const signIn = (type: any) => {
    dispatch(socialLogin(type, props.router.navigate));
  };

  const socialResponse = (type: any) => {
    signIn(type);
  };

  const verifyCallback = (recaptchaToken: string) => {
    // Here you will get the final recaptchaToken!!!
    console.log("Recaptcha is valid");
    setReCaptchaToken(recaptchaToken);
  };

  useEffect(() => {
    if (error) {
      setModalError(true);

      setTimeout(() => {
        dispatch(resetLoginMsgFlag());
      }, 1000);
    }
  }, [dispatch, error]);

  return (
    <React.Fragment>
      <Container fluid className="d-flex account-pages">
        <Col className="container-left-auth d-none d-lg-flex">
          <div className="details-auth">
            <img src={ImageAuth} alt="Duas pessoas dando risada" />
            <h1>Instituto Global.</h1>
            <p>Um mundo de soluções ESG.</p>
          </div>
        </Col>
        <Col xl={3} className="container-right-auth">
          <div className="w-100 h-100">
            <Row>
              <div className="text-primary p-4">
                <img src={Logo} alt="" />
              </div>
            </Row>

            <Row className="p-3 form-auth">
              <h3>Bem-vindo(a)!</h3>
              <p>Faça login na plataforma Instituto Global</p>

              <div>
                <div>
                  <Form
                    className="form-horizontal"
                    validate={validation}
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Usuário</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Usuário"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                        disabled={loading}
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <Label className="form-label">Senha</Label>

                        <Link
                          to="#"
                          className="forget-password-link"
                          onClick={(e: any) => {
                            e.preventDefault();
                          }}
                        >
                          {" "}
                          Esqueceu a senha?
                        </Link>
                      </div>
                      <div className="input-group auth-pass-inputgroup">
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Senha"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                          disabled={loading}
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Lembrar senha
                        </label>
                      </div> */}

                    <div className="mt-3 d-grid">
                      <Button
                        className="btn btn-primary btn-block btn-auth"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <Spinner></Spinner> : "Entrar"}
                      </Button>
                    </div>

                    {/* <div className="mt-4 text-center sign-up-text">
                        Ainda não tem uma conta?{" "}
                        <Link to="/forgot-password">Cadastre-se</Link>
                      </div> */}
                  </Form>
                </div>
              </div>
            </Row>
          </div>
        </Col>

        <Modal
          className="modal-auth-page"
          size="sm"
          isOpen={modalError}
          toggle={() => {
            toggleModalError();
          }}
          centered
        >
          <div className="modal-body">
            <DangerousOutlinedIcon />

            <p>Usuário ou senha inválidos.</p>

            <Button
              onClick={() => {
                toggleModalError();
              }}
            >
              Entendi
            </Button>
          </div>
        </Modal>
      </Container>

      <ReCaptcha
        sitekey="6LfAEDIpAAAAAHHvZqYlhJuJzJUJc_tTsUueunQu"
        action="login"
        verifyCallback={(e) => verifyCallback(e)}
      />
    </React.Fragment>
  );
};

export default withRouter(Login);
