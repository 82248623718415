import { getClientDashboard } from "helpers/fakebackend_helper";
import {
  apiError,
  isFetching,
  fetchSuccess,
} from "./reducer";

export const fetchData = (user: any | null) => async (dispatch: any) => {
  try {
    dispatch(isFetching());

    const response = await getClientDashboard(null);

    dispatch(fetchSuccess(response));
  } catch (error) {
    console.log("erro ao fazer login", error);
    dispatch(apiError(error));
  }
};

