export const cryptoOrderData = [
  {
    id: "#SK3215",
    date: "03 Mar, 2020",
    type: "Buy",
    currency: "Bitcoin",
    amount: "1.00952 BTC",
    amountinUSD: "$ 9067.62",
  },
  {
    id: "#SK3216",
    date: "04 Mar, 2020",
    type: "Sell",
    currency: "Ethereum",
    amount: "0.00413 ETH",
    amountinUSD: "$ 2123.01",
  },
  {
    id: "#SK3217",
    date: "04 Mar, 2020",
    type: "Buy",
    currency: "Bitcoin",
    amount: "1.00952 BTC",
    amountinUSD: "$ 9067.62",
  },
  {
    id: "#SK3218",
    date: "03 Mar, 2020",
    type: "Buy",
    currency: "Bitcoin",
    amount: "1.00952 BTC",
    amountinUSD: "$ 9067.62",
  },
  {
    id: "#SK3219",
    date: "03 Mar, 2020",
    type: "Buy",
    currency: "Bitcoin",
    amount: "1.00952 BTC",
    amountinUSD: "$ 9067.62",
  },
  {
    id: "#SK3220",
    date: "03 Mar, 2020",
    type: "Buy",
    currency: "Bitcoin",
    amount: "1.00952 BTC",
    amountinUSD: "$ 9067.62",
  },
  {
    id: "#SK3221",
    date: "03 Mar, 2020",
    type: "Buy",
    currency: "Bitcoin",
    amount: "1.00952 BTC",
    amountinUSD: "$ 9067.62",
  },
  {
    id: "#SK3222",
    date: "03 Mar, 2020",
    type: "Buy",
    currency: "Bitcoin",
    amount: "1.00952 BTC",
    amountinUSD: "$ 9067.62",
  },
  {
    id: "#SK3223",
    date: "03 Mar, 2020",
    type: "Buy",
    currency: "Bitcoin",
    amount: "1.00952 BTC",
    amountinUSD: "$ 9067.62",
  },
  {
    id: "#SK3224",
    date: "03 Mar, 2020",
    type: "Buy",
    currency: "Bitcoin",
    amount: "1.00952 BTC",
    amountinUSD: "$ 9067.62",
  },
  {
    id: "#SK3225",
    date: "03 Mar, 2020",
    type: "Buy",
    currency: "Bitcoin",
    amount: "1.00952 BTC",
    amountinUSD: "$ 9067.62",
  },
  {
    id: "#SK3226",
    date: "03 Mar, 2020",
    type: "Buy",
    currency: "Bitcoin",
    amount: "1.00952 BTC",
    amountinUSD: "$ 9067.62",
  },
];
