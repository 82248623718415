export const visitor = [
  // month
  {
    id: 1,
    data: {
      categories: [
        "28' Jan",
        "13' 05' Feb",
        "13' Mar",
        "13' Apr",
        "13' May",
        "13' Jun",
        "13' Jul",
        "13' Aug",
        "13' Sep",
        "13' Oct",
        "13' Nov",
        "13' Dec",
      ],
      Currentdata: [18, 21, 45, 36, 65, 47, 51, 32, 40, 28, 31, 26],
      Previousdata: [30, 11, 22, 18, 32, 23, 58, 45, 30, 36, 15, 34],
    },
    today: 1024,
    thisMonth: 12356,
    thisYear: 102354,
  },
  // 6 month
  {
    id: 2,
    data: {
      categories: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      Currentdata: [51, 32, 40, 28, 31, 26],
      Previousdata: [58, 45, 30, 36, 15, 34],
    },
    today: 2022,
    thisMonth: 39485,
    thisYear: 102938,
  },
  // 1 year
  {
    id: 3,
    data: {
      categories: [
        "Jan '21",
        "Feb '21",
        "Mar '21",
        "Apr '21",
        "May '21",
        "Jun '21",
        "Jul '21",
        "Aug '21",
        "Sep '21",
        "Oct '21",
        "Nov '21",
        "2022",
      ],
      Currentdata: [19, 27, 47, 56, 68, 41, 57, 22, 29, 58, 20, 18],
      Previousdata: [51, 46, 28, 17, 67, 13, 49, 47, 57, 84, 72, 16],
    },
    today: 1029,
    thisMonth: 56473,
    thisYear: 938271,
  },
  // all
  {
    id: 4,
    data: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      Currentdata: [18, 21, 45, 36, 65, 47, 51, 32, 40, 28, 31, 26],
      Previousdata: [30, 11, 22, 18, 32, 23, 58, 45, 30, 36, 15, 34],
    },
    today: 6095,
    thisMonth: 47893,
    thisYear: 348098,
  },
];
