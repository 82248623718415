import React, { useState } from "react";

import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Card,
  CardBody,
} from "reactstrap";

import classnames from "classnames";

import Breadcrumb from "Components/Common/Breadcrumb";

import CreateIndividualUser from "Components/partnerCompany/userManagement/CreateIndividualUser";
import CreateUserInBulk from "Components/partnerCompany/userManagement/CreateUserInBulk";

import "./UserManagement.styles.scss";

const UserManagement = () => {
  //meta title
  document.title = "Gestão de Usuários | Instituto Global ESG";

  const [activeTab, setactiveTab] = useState<any>("1");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Início" breadcrumbItem="Gestão de Usuários" />

          <div className="userManagementTabs">
            <Row className="w-100 justify-content-center">
              <Col lg={2} sm={3}>
                <Nav className="flex-column gap-2" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setactiveTab("1");
                      }}
                    >
                      <i className="mdi mdi-account-outline d-block check-nav-icon" />
                      <p className="fw-bold">Individual</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        setactiveTab("2");
                      }}
                    >
                      <i className="mdi mdi-account-group" />

                      <p className="fw-bold">Em massa</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg={8} sm={9} className="mt-3 mt-lg-0">
                <Card className="w-100 p-lg-4">
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <CreateIndividualUser />

                      <CreateUserInBulk />
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserManagement;
