import React, { useEffect } from "react";
import "./App.css";
import { clientRoutes, partnerRoutes, publicRoutes } from "./Routes/allRoutes";
import { Route, Routes } from "react-router-dom";
import VerticalLayout from "./Layouts/VerticalLayout";
import HorizotanlLayout from "./Layouts/HorizontalLayout/index";
import "./assets/scss/theme.scss";
import NonAuthLayout from "./Layouts/NonLayout";
import { loadReCaptcha } from "react-recaptcha-v3";

//constants
import { LAYOUT_TYPES } from "./Components/constants/layout";

import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import AuthProtected from "Routes/AuthProtected";
import { authSelector } from "slices/auth/reducer";
import { useAppSelector } from "slices";

fakeBackend();

const getLayout = (layoutType: any) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case LAYOUT_TYPES.VERTICAL:
      Layout = VerticalLayout;
      break;
    case LAYOUT_TYPES.HORIZONTAL:
      Layout = HorizotanlLayout;
      break;
    default:
      break;
  }
  return Layout;
};

function App() {
  const selectLeadData = createSelector(
    (state: any) => state.Layout,
    (layoutTypes) => layoutTypes
  );
  const { layoutTypes } = useSelector(selectLeadData);

  const { role } = useAppSelector(authSelector);

  const Layout = getLayout(layoutTypes);

  useEffect(() => {
    // !!!! Colocar chave no ENV
    loadReCaptcha("6LfAEDIpAAAAAHHvZqYlhJuJzJUJc_tTsUueunQu");
  }, []);

  const routes = role === "Partner" ? partnerRoutes : clientRoutes;

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
          />
        ))}
        {routes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={
              <React.Fragment>
                <AuthProtected>
                  <Layout>{route.component}</Layout>
                </AuthProtected>
              </React.Fragment>
            }
          />
        ))}
      </Routes>
    </React.Fragment>
  );
}

export default App;
