import { Col, Input, Row, TabPane } from "reactstrap";

const PartnerCompanyFinancial = () => {
  return (
    <TabPane
      tabId="4"
      id="v-pills-payment"
      role="tabpanel"
      aria-labelledby="v-pills-payment-tab"
      className="financialPartnerCompany-tab"
    >
      <Row>
        <Col sm={3}>
          <p className="title-financial">Segmentação</p>
        </Col>

        <Col>
          <p className="description-financial">Segmentação X</p>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <p className="title-financial">Instituição Financeira</p>
        </Col>
        <Col>
          <p className="description-financial">Banco J.P Morgan SA - 324</p>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <p className="title-financial">Conta Bancária</p>
        </Col>
        <Col>
          <p className="description-financial">000231-02</p>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <p className="title-financial">Titular</p>
        </Col>
        <Col>
          <p className="description-financial">Carlos Alberto Silva Jr.</p>
        </Col>
      </Row>
    </TabPane>
  );
};

export default PartnerCompanyFinancial;
