import React from "react";
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../../ChartDynamicColor";

const StackedColumnChart = ({ dataColors, periodData }) => {
  const stackedColumnChartColors = getChartColorsArray(dataColors);

  const options: any = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Maio",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      labels: {
        show: true,
      },
    },
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...periodData]}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default StackedColumnChart;
