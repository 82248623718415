import React from "react";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import Breadcrumb from "Components/Common/Breadcrumb";

import "./support.styles.scss";

const Support = () => {
  document.title = "Suporte | Instituto Global ESG";

  const validation: any = useFormik({
    initialValues: {
      name: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor, digite seu nome."),
      subject: Yup.string().required("Por favor, digite o assunto."),
      message: Yup.string().required("Por favor, digite sua mensagem."),
    }),
    onSubmit: (values: any) => {
      // console.log(values)
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Home" breadcrumbItem="Suporte" />

          <div className="support-page">
            <div className="support-content">
              <h5 className="support-content_title">Contato</h5>
              <p className="support-content_description">
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien.
              </p>

              <div className="support-contact">
                <div className="d-flex gap-3">
                  <p className="support-contact_title">Telefone:</p>
                  <p className="support-contact_description">(123) 123-1234</p>
                </div>
                <div className="d-flex gap-3">
                  <p className="support-contact_title">E-mail:</p>
                  <p className="support-contact_description">email@gmail.com</p>
                </div>
              </div>

              <Form className="support-form" onSubmit={validation.handleSubmit}>
                <FormGroup className="mb-4" row>
                  <Label
                    htmlFor="support-name"
                    md="2"
                    className="col-form-label"
                  >
                    Nome
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      id="support-name"
                      placeholder="Digite seu nome"
                      name="name"
                      value={validation.values.name}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
                <FormGroup className="mb-4" row>
                  <Label
                    htmlFor="support-subject"
                    md="2"
                    className="col-form-label"
                  >
                    Assunto
                  </Label>
                  <Col md={10}>
                    <Input
                      type="text"
                      id="support-subject"
                      placeholder="Digite o assunto"
                      name="subject"
                      value={validation.values.subject}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.subject && validation.errors.subject
                          ? true
                          : false
                      }
                    />
                    {validation.touched.subject && validation.errors.subject ? (
                      <FormFeedback type="invalid">
                        {validation.errors.subject}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
                <FormGroup className="mb-4" row>
                  <Label
                    htmlFor="support-message"
                    md="2"
                    className="col-form-label"
                  >
                    Mensagem
                  </Label>
                  <Col md={10}>
                    <Input
                      type="textarea"
                      id="support-message"
                      placeholder="Digite o assunto"
                      name="message"
                      value={validation.values.message}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.message && validation.errors.message
                          ? true
                          : false
                      }
                    />
                    {validation.touched.message && validation.errors.message ? (
                      <FormFeedback type="invalid">
                        {validation.errors.message}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <button className="btn support-form_btn">Salvar</button>
                </div>
              </Form>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Support;
