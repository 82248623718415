import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Row,
  Col,
  Input,
  TabPane,
  Form,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";

import "./CreateIndividualUser.styles.scss";

const CreateIndividualUser = () => {
  const validation: any = useFormik({
    initialValues: {
      name: "",
      document: "",
      email: "",
      phone: "",
      zip: "",
      address: "",
      addressNumber: "",
      city: "",
      complement: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor, digite o nome completo."),
      document: Yup.string().required("Por favor, digite o CPF / CNPJ."),
      email: Yup.string().email().required("Por favor, digite seu email."),
      phone: Yup.string().required("Por favor, digite o telefone."),
      zip: Yup.string().required("Por favor, digite o CEP."),
      address: Yup.string().required("Por favor, digite o endereço."),
      addressNumber: Yup.string().required(
        "Por favor, digite o número do endereço."
      ),
      city: Yup.string().required("Por favor, digite a cidade."),
    }),
    onSubmit: (values: any) => {
      // console.log(values)
    },
  });

  return (
    <TabPane tabId="1" className="userManagementTab">
      <h2 className="title-tab_userManagement">1. Dados do Usuário</h2>

      <div>
        <Form onSubmit={validation.handleSubmit}>
          <Row className="d-flex flex-row">
            <Col>
              <FormGroup>
                <Label
                  htmlFor="create-user-individual-name"
                  className="col-form-label"
                >
                  Nome Completo
                </Label>
                <Col>
                  <Input
                    type="text"
                    id="create-user-individual-name"
                    placeholder="Digite o nome completo"
                    name="name"
                    value={validation.values.name}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="create-user-individual-document"
                  className="col-form-label"
                >
                  CPF / CNPJ
                </Label>
                <Col>
                  <Input
                    type="text"
                    id="create-user-individual-document"
                    placeholder="Digite a matricula"
                    name="document"
                    value={validation.values.document}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.document && validation.errors.document
                        ? true
                        : false
                    }
                  />
                  {validation.touched.document && validation.errors.document ? (
                    <FormFeedback type="invalid">
                      {validation.errors.document}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row className="d-flex flex-row">
            <Col>
              <FormGroup>
                <Label
                  htmlFor="create-user-individual-phone"
                  className="col-form-label"
                >
                  Telefone
                </Label>
                <Col>
                  <Input
                    type="text"
                    id="create-user-individual-phone"
                    placeholder="Digite o telefone"
                    name="phone"
                    value={validation.values.phone}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.phone && validation.errors.phone
                        ? true
                        : false
                    }
                  />
                  {validation.touched.phone && validation.errors.phone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phone}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="create-user-individual-email"
                  className="col-form-label"
                >
                  Email
                </Label>
                <Col>
                  <Input
                    type="text"
                    id="create-user-individual-email"
                    placeholder="Digite o email"
                    name="email"
                    value={validation.values.email}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row className="d-flex flex-row">
            <Col>
              <FormGroup>
                <Label
                  htmlFor="create-user-individual-zip"
                  className="col-form-label"
                >
                  CEP
                </Label>
                <Col>
                  <Input
                    type="text"
                    id="create-user-individual-zip"
                    placeholder="Digite o CEP"
                    name="zip"
                    value={validation.values.zip}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.zip && validation.errors.zip
                        ? true
                        : false
                    }
                  />
                  {validation.touched.zip && validation.errors.zip ? (
                    <FormFeedback type="invalid">
                      {validation.errors.zip}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="create-user-individual-city"
                  className="col-form-label"
                >
                  Cidade
                </Label>
                <Col>
                  <Input
                    type="text"
                    id="create-user-individual-city"
                    placeholder="Digite a cidade"
                    name="city"
                    value={validation.values.city}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.city && validation.errors.city
                        ? true
                        : false
                    }
                  />
                  {validation.touched.city && validation.errors.city ? (
                    <FormFeedback type="invalid">
                      {validation.errors.city}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row className="d-flex flex-row">
            <Col>
              <FormGroup>
                <Label
                  htmlFor="create-user-individual-address"
                  className="col-form-label"
                >
                  Rua
                </Label>
                <Col>
                  <Input
                    type="text"
                    id="create-user-individual-address"
                    placeholder="Digite o endereço"
                    name="address"
                    value={validation.values.address}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.address && validation.errors.address
                        ? true
                        : false
                    }
                  />
                  {validation.touched.address && validation.errors.address ? (
                    <FormFeedback type="invalid">
                      {validation.errors.address}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="create-user-individual-addressNumber"
                  className="col-form-label"
                >
                  Número
                </Label>
                <Col>
                  <Input
                    type="text"
                    id="create-user-individual-addressNumber"
                    placeholder="Digite o número do endereço"
                    name="addressNumber"
                    value={validation.values.addressNumber}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.addressNumber &&
                      validation.errors.addressNumber
                        ? true
                        : false
                    }
                  />
                  {validation.touched.addressNumber &&
                  validation.errors.addressNumber ? (
                    <FormFeedback type="invalid">
                      {validation.errors.addressNumber}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row className="d-flex flex-row">
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label
                  htmlFor="create-user-individual-complement"
                  className="col-form-label"
                >
                  Complemento
                </Label>
                <Input
                  type="text"
                  id="create-user-individual-complement"
                  placeholder="Digite o complemento"
                  name="complement"
                  value={validation.values.complement}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.complement &&
                    validation.errors.complement
                      ? true
                      : false
                  }
                />
                {validation.touched.complement &&
                validation.errors.complement ? (
                  <FormFeedback type="invalid">
                    {validation.errors.complement}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>

      <Row className="mt-4">
        <button className="btn btn-CreateIndividualUser">
          Criar Usuário
        </button>
      </Row>
    </TabPane>
  );
};

export default CreateIndividualUser;
