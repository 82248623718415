import { useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";

import Dropzone from "react-dropzone";

import {
  Col,
  Input,
  TabPane,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Row,
} from "reactstrap";

const PersonalInformation = () => {
  const [selectedFiles, setselectedFiles] = useState<any>([]);

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  const validation: any = useFormik({
    initialValues: {
      corporateReason: "",
      cnpj: "",
      fantasyName: "",
      email: "",
      phone: "",
      zip: "",
      city: "",
      address: "",
      addressNumber: "",
      complement: "",
    },
    validationSchema: Yup.object({
      corporateReason: Yup.string().required(
        "Por favor, digite sua razão social."
      ),
      cnpj: Yup.string().required("Por favor, digite seu CNPJ."),
      fantasyName: Yup.string().required(
        "Por favor, digite seu Nome Fantasia."
      ),
      email: Yup.string().email().required("Por favor, digite seu email."),
      phone: Yup.string().required("Por favor, digite seu telefone."),
      zip: Yup.string().required("Por favor, digite seu CEP."),
      city: Yup.string().required("Por favor, digite sua cidade."),
      address: Yup.string().required("Por favor, digite seu endereço."),
      addressNumber: Yup.string().required(
        "Por favor, digite o número do seu endereço."
      ),
    }),
    onSubmit: (values: any) => {
      // console.log(values)
    },
  });

  return (
    <TabPane tabId="1">
      <div>
        <Form onSubmit={validation.handleSubmit}>
          <Row>
            <Col>
              <Label>Foto do Perfil (máx. 1MB)</Label>
              <Dropzone
                onDrop={(acceptedFiles: any) => {
                  handleAcceptedFiles(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }: any) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick mt-2"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4>Arraste aqui ou clique para escolher.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="corporate-reason" className="col-form-label">
                  Razão Social
                </Label>
                <Input
                  type="text"
                  id="corporate-reason"
                  placeholder="Digite a razão social"
                  name="corporateReason"
                  value={validation.values.corporateReason}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.corporateReason &&
                    validation.errors.corporateReason
                      ? true
                      : false
                  }
                />
                {validation.touched.corporateReason &&
                validation.errors.corporateReason ? (
                  <FormFeedback type="invalid">
                    {validation.errors.corporateReason}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="cnpj" md="2" className="col-form-label">
                  CNPJ
                </Label>
                <Input
                  type="text"
                  id="cnpj"
                  placeholder="Digite seu CNPJ"
                  name="cnpj"
                  value={validation.values.cnpj}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.cnpj && validation.errors.cnpj
                      ? true
                      : false
                  }
                />
                {validation.touched.cnpj && validation.errors.cnpj ? (
                  <FormFeedback type="invalid">
                    {validation.errors.cnpj}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="fantasyName" className="col-form-label">
                  Nome Fantasia
                </Label>
                <Input
                  type="email"
                  id="fantasyName"
                  placeholder="Digite seu Nome Fantasia"
                  name="fantasyName"
                  value={validation.values.fantasyName}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.fantasyName &&
                    validation.errors.fantasyName
                      ? true
                      : false
                  }
                />
                {validation.touched.fantasyName &&
                validation.errors.fantasyName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.fantasyName}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label
                  htmlFor="billing-email-address"
                  md="2"
                  className="col-form-label"
                >
                  Email
                </Label>
                <Input
                  type="email"
                  id="billing-email-address"
                  placeholder="Digite seu email"
                  name="email"
                  value={validation.values.email}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="phone" className="col-form-label">
                  Telefone
                </Label>
                <Input
                  type="text"
                  id="phone"
                  placeholder="Digite seu número de telefone"
                  name="phone"
                  value={validation.values.phone}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.phone && validation.errors.phone
                      ? true
                      : false
                  }
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="zip-code" className="col-form-label">
                  CEP
                </Label>
                <Input
                  type="text"
                  id="zip-code"
                  placeholder="Digite seu CEP"
                  name="zip"
                  value={validation.values.zip}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.zip && validation.errors.zip
                      ? true
                      : false
                  }
                />
                {validation.touched.zip && validation.errors.zip ? (
                  <FormFeedback type="invalid">
                    {validation.errors.zip}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="city" className="col-form-label">
                  Cidade
                </Label>
                <Input
                  type="text"
                  id="city"
                  placeholder="Digite sua Cidade"
                  name="city"
                  value={validation.values.city}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.city && validation.errors.city
                      ? true
                      : false
                  }
                />
                {validation.touched.city && validation.errors.city ? (
                  <FormFeedback type="invalid">
                    {validation.errors.city}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="address" className="col-form-label">
                  Logradouro
                </Label>
                <Input
                  type="text"
                  id="address"
                  placeholder="Digite seu endereço"
                  name="address"
                  value={validation.values.address}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.address && validation.errors.address
                      ? true
                      : false
                  }
                />
                {validation.touched.address && validation.errors.address ? (
                  <FormFeedback type="invalid">
                    {validation.errors.address}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="addressNumber" className="col-form-label">
                  Número
                </Label>
                <Input
                  type="text"
                  id="addressNumber"
                  placeholder="Digite o número do seu endereço"
                  name="addressNumber"
                  value={validation.values.addressNumber}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.addressNumber &&
                    validation.errors.addressNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.addressNumber &&
                validation.errors.addressNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.addressNumber}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="complement" className="col-form-label">
                  Complemento
                </Label>
                <Input
                  type="text"
                  id="complement"
                  placeholder="Digite o complemento"
                  name="complement"
                  value={validation.values.complement}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.complement &&
                    validation.errors.complement
                      ? true
                      : false
                  }
                />
                {validation.touched.complement &&
                validation.errors.complement ? (
                  <FormFeedback type="invalid">
                    {validation.errors.complement}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>

          <div className="mt-4">
            <button className="btn btn-save-perfil">Salvar</button>
          </div>
        </Form>
      </div>
    </TabPane>
  );
};

export default PersonalInformation;
