import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Col,
  Input,
  TabPane,
  Form,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";

const Security = () => {
  const validation: any = useFormik({
    initialValues: {
      newPassword: "",
      newPasswordConfirm: "",
      code2FA: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required("Por favor, digite sua nova senha."),
      newPasswordConfirm: Yup.string()
        .email()
        .required("Por favor, digite a confirmação da sua nova senha."),
      code2FA: Yup.string().required("Por favor, digite seu código."),
    }),
    onSubmit: (values: any) => {
      // console.log(values)
    },
  });

  return (
    <TabPane
      tabId="2"
      id="v-pills-payment"
      role="tabpanel"
      aria-labelledby="v-pills-payment-tab"
    >
      <div>
        <Form onSubmit={validation.handleSubmit}>
          <FormGroup className="mb-4" row>
            <Label htmlFor="newPassword" md="2" className="col-form-label">
              Nova Senha
            </Label>
            <Col md={10}>
              <Input
                type="text"
                id="newPassword"
                placeholder="Digite sua nova senha"
                name="newPassword"
                value={validation.values.newPassword}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.newPassword &&
                  validation.errors.newPassword
                    ? true
                    : false
                }
              />
              {validation.touched.newPassword &&
              validation.errors.newPassword ? (
                <FormFeedback type="invalid">
                  {validation.errors.newPassword}
                </FormFeedback>
              ) : null}
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label
              htmlFor="newPasswordConfirm"
              md="2"
              className="col-form-label"
            >
              Confirmar nova senha
            </Label>
            <Col md={10}>
              <Input
                type="text"
                id="newPasswordConfirm"
                placeholder="Digite a confirmação da sua nova senha"
                name="newPasswordConfirm"
                value={validation.values.newPasswordConfirm}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.newPasswordConfirm &&
                  validation.errors.newPasswordConfirm
                    ? true
                    : false
                }
              />
              {validation.touched.newPasswordConfirm &&
              validation.errors.newPasswordConfirm ? (
                <FormFeedback type="invalid">
                  {validation.errors.newPasswordConfirm}
                </FormFeedback>
              ) : null}
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label htmlFor="code2FA" md="2" className="col-form-label">
              Código 2FA
            </Label>
            <Col md={10}>
              <Input
                type="text"
                id="code2FA"
                placeholder="Digite seu código 2FA"
                name="code2FA"
                value={validation.values.code2FA}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.code2FA && validation.errors.code2FA
                    ? true
                    : false
                }
              />
              {validation.touched.code2FA && validation.errors.code2FA ? (
                <FormFeedback type="invalid">
                  {validation.errors.code2FA}
                </FormFeedback>
              ) : null}
            </Col>
          </FormGroup>

          <div className="mt-4">
            <button className="btn btn-save-perfil">Salvar</button>
          </div>
        </Form>
      </div>
    </TabPane>
  );
};

export default Security;
