import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Form,
  Input,
  Label,
  FormFeedback,
  Container,
  Button,
  Spinner,
  Modal,
} from "reactstrap";

import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";

import cep from "cep-promise";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useAppSelector } from "slices";
import { authSelector } from "slices/auth/reducer";

import ImageAuth from "../../assets/images/bg_auth.png";
import Logo from "../../assets/images/logo-auth.png";

import { resetLoginMsgFlag, socialLogin, updateUser } from "slices/auth/thunk";

import withRouter from "Components/Common/withRouter";

import "./auth.styles.scss";

const Login = (props: any) => {
  document.title = "Cadastro | Instituto Global ESG";

  const dispatch: any = useDispatch();

  const { error, loading } = useAppSelector(authSelector);

  const [modalError, setModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleModalError() {
    setModalError(!modalError);
    setErrorMessage("");
    removeBodyCss();
  }

  const validation: any = useFormik({
    initialValues: {
      name: "",
      email: "",
      document: "",
      phone: "",
      cep: "",
      city: "",
      address: "",
      addressNumber: "",
      complement: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor, digite o seu nome."),
      email: Yup.string()
        .email("E-mail inválido")
        .required("Por favor, digite o seu email."),
      document: Yup.string()
        .required("Por favor, digite o seu CPF ou CNPJ.")
        .test("is-cpf-cnpj", "CPF ou CNPJ inválido", (value: any) => {
          const regexCPF = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
          const regexCNPJ = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

          return regexCPF.test(value) || regexCNPJ.test(value);
        }),
      phone: Yup.string()
        .matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, "Telefone inválido")
        .required("Por favor, digite o seu telefone."),
      cep: Yup.string()
        .matches(/^\d{5}-\d{3}$/, "CEP inválido") // Aceita o formato NNNNN-NNN
        .required("Por favor, digite o seu CEP"),
      city: Yup.string().required("Por favor, digite a sua cidade."),
      state: Yup.string().required("Por favor, digite seu estado."),
      address: Yup.string().required("Por favor, digite o seu endereço."),
      addressNumber: Yup.string().required(
        "Por favor, digite o numero do seu endereço."
      ),
      complement: Yup.string().required("Por favor, digite o seu complemento."),
    }),
    onSubmit: (values: any) => {
      dispatch(updateUser(values, props.router.navigate));
    },
  });

  const handleCpfCnpjChange = (event) => {
    const { value } = event.target;
    const maskedValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos

    const formattedValue =
      maskedValue.length <= 11
        ? maskedValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1.$2.$3-") // CPF
        : maskedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1.$2.$3/$4-"); // CNPJ

    validation.setFieldValue("document", formattedValue);
  };

  const handleTelefoneChange = (event) => {
    const { value } = event.target;
    const maskedValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos

    const formattedValue =
      maskedValue.length <= 10
        ? maskedValue.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3") // (DD) NNNN-NNNN
        : maskedValue.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"); // (DD) NNNNN-NNNN

    validation.setFieldValue("phone", formattedValue);
  };

  const handleCepChange = (event) => {
    const { value } = event.target;
    const maskedValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos

    const formattedValue = maskedValue.replace(/(\d{5})(\d{3})/, "$1-$2");

    validation.setFieldValue("cep", formattedValue);

    if (formattedValue.length === 9) {
      cep(formattedValue).then((addr) => {
        console.log("test", addr);
        validation.setFieldValue("address", addr.street);
        validation.setFieldValue("city", addr.city);
        validation.setFieldValue("state", addr.state);
        // validation.setFieldValue("neighborhood", formattedValue);
      });
    }
  };

  const signIn = (type: any) => {
    dispatch(socialLogin(type, props.router.navigate));
  };

  const socialResponse = (type: any) => {
    signIn(type);
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);

      setModalError(true);

      setTimeout(() => {
        dispatch(resetLoginMsgFlag());
      }, 1000);
    }
  }, [dispatch, error]);

  return (
    <React.Fragment>
      <Container fluid className="d-flex account-pages">
        <Col className="container-left-auth d-none d-lg-flex">
          <img src={ImageAuth} alt="Duas pessoas dando risada" />

          <div className="details-auth">
            <h1>Instituto Global.</h1>
            <p>Um mundo de soluções ESG.</p>
          </div>
        </Col>
        <Col xl={3} className="container-right-auth register">
          <div>
            <Row>
              <div className="text-primary p-4">
                <img src={Logo} alt="" />
              </div>
            </Row>

            <Row className="p-3 flex-row">
              <h3 className="mb-5">Cadastre-se</h3>

              <div>
                <div>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Nome Completo*</Label>
                      <Input
                        name="name"
                        className="form-control"
                        placeholder="Nome completo"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                        disabled={loading}
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">CPF / CNPJ*</Label>
                      <Input
                        name="document"
                        className="form-control"
                        placeholder="CPF / CNPJ"
                        type="text"
                        onChange={handleCpfCnpjChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.document || ""}
                        invalid={
                          validation.touched.document &&
                          validation.errors.document
                            ? true
                            : false
                        }
                        maxLength={18}
                        disabled={loading}
                      />
                      {validation.touched.document &&
                      validation.errors.document ? (
                        <FormFeedback type="invalid">
                          {validation.errors.document}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="d-flex gap-2 mb-3">
                      <Col>
                        <Label className="form-label">Email*</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                          disabled={loading}
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      <Col>
                        <Label className="form-label">Telefone*</Label>
                        <Input
                          name="phone"
                          className="form-control"
                          placeholder="Telefone"
                          type="text"
                          onChange={handleTelefoneChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone && validation.errors.phone
                              ? true
                              : false
                          }
                          maxLength={15}
                          disabled={loading}
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phone}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </div>

                    <div className="d-flex gap-2 mb-3">
                      <Col>
                        <Label className="form-label">CEP*</Label>
                        <Input
                          name="cep"
                          className="form-control"
                          placeholder="CEP"
                          type="text"
                          onChange={handleCepChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.cep || ""}
                          invalid={
                            validation.touched.cep && validation.errors.cep
                              ? true
                              : false
                          }
                          maxLength={9}
                          disabled={loading}
                        />
                        {validation.touched.cep && validation.errors.cep ? (
                          <FormFeedback type="invalid">
                            {validation.errors.cep}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      <Col>
                        <Label className="form-label">Cidade*</Label>
                        <Input
                          name="city"
                          className="form-control"
                          placeholder="Cidade"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.city || ""}
                          invalid={
                            validation.touched.city && validation.errors.city
                              ? true
                              : false
                          }
                          disabled={loading}
                        />
                        {validation.touched.city && validation.errors.city ? (
                          <FormFeedback type="invalid">
                            {validation.errors.city}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col>
                        <Label className="form-label">Estado*</Label>
                        <Input
                          name="state"
                          className="form-control"
                          placeholder="Estado"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.state || ""}
                          invalid={
                            validation.touched.state && validation.errors.state
                              ? true
                              : false
                          }
                          disabled={loading}
                        />
                        {validation.touched.state && validation.errors.state ? (
                          <FormFeedback type="invalid">
                            {validation.errors.state}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </div>

                    <div className="d-flex gap-2 mb-3">
                      <Col>
                        <Label className="form-label">Rua*</Label>
                        <Input
                          name="address"
                          className="form-control"
                          placeholder="Rua"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address || ""}
                          invalid={
                            validation.touched.address &&
                            validation.errors.address
                              ? true
                              : false
                          }
                          disabled={loading}
                        />
                        {validation.touched.address &&
                        validation.errors.address ? (
                          <FormFeedback type="invalid">
                            {validation.errors.address}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col>
                        <Label className="form-label">Número*</Label>
                        <Input
                          name="addressNumber"
                          className="form-control"
                          placeholder="Número"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.addressNumber || ""}
                          invalid={
                            validation.touched.addressNumber &&
                            validation.errors.addressNumber
                              ? true
                              : false
                          }
                          disabled={loading}
                        />
                        {validation.touched.addressNumber &&
                        validation.errors.addressNumber ? (
                          <FormFeedback type="invalid">
                            {validation.errors.addressNumber}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </div>
                    <div className="d-flex gap-2 mb-3">
                      <Col>
                        <Label className="form-label">Complemento</Label>
                        <Input
                          name="complement"
                          className="form-control"
                          placeholder="Complemento"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.complement || ""}
                          invalid={
                            validation.touched.complement &&
                            validation.errors.complement
                              ? true
                              : false
                          }
                          disabled={loading}
                        />
                        {validation.touched.complement &&
                        validation.errors.complement ? (
                          <FormFeedback type="invalid">
                            {validation.errors.complement}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </div>
                    <div className="d-flex gap-2 mb-3">
                      <Col>
                        <Label className="form-label">Senha*</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder="Senha"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                          disabled={loading}
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col>
                        <Label className="form-label">Confirmar Senha*</Label>
                        <Input
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Confirmar Senha"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                              ? true
                              : false
                          }
                          disabled={loading}
                        />
                        {validation.touched.confirmPassword &&
                        validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </div>

                    <div className="mt-4 d-grid">
                      <Button
                        className="btn btn-primary btn-block btn-auth"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <Spinner /> : "Cadastrar"}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Row>
          </div>
        </Col>

        <Modal
          className="modal-auth-page"
          size="sm"
          isOpen={modalError}
          toggle={() => {
            toggleModalError();
          }}
          centered
        >
          <div className="modal-body">
            <DangerousOutlinedIcon />

            <p>{errorMessage}</p>

            <Button
              onClick={() => {
                toggleModalError();
              }}
            >
              Entendi
            </Button>
          </div>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Login);
