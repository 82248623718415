import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

export const initialState = {
    balance: {
    value: '', // saldo carteira
    },
    totalCounters: {
        totalBillsPaid: null, // Total de contas pagas
        totalBillsSaved: '', // Total em dinheiro economizado em contas
    },
    simpleTransactionHistory: [
    // lista de transações simplificado
    {
        id: null,
        description: '',
        value: '',
        expiresIn: '',
        createdAt: '',
    },
    ],
    myVouchers: [
    {
        id: null, // ID do vale
        value: '', // Valor do vale
        expiresIn: '', // data de expiração para mostrar visualmente se uma transação está expirada
        partnerId: 0, // ID da empresa parceiro
        partnerName: '', // Nome da empresa parceiro
        partnerLogoUrl: '' || 'https://i.ibb.co/qdfJd32/Grupo-Arnone-Logomarca-04.png', // URL da Logo da empresa parceiro
        createdAt: '', // Data de criação para ordenação
    },
    ],

  // layout
  error: "", // for error msg
  loading: false,
  isUserLogged: false,
  errorMsg: false, // for error
};

const clientDashboardSlice = createSlice({
  name: "clientDashboard",
  initialState,
  reducers: {
    fetchSuccess(state, action) {
      // User data
      state.balance = action.payload.balance;
      state.totalCounters = action.payload.totalCounters;
      state.simpleTransactionHistory = action.payload.simpleTransactionHistory;
      state.myVouchers = action.payload.myVouchers;
      // Layout data
      state.loading = false;
      state.errorMsg = false;
    },

    isFetching(state) {
      state.loading = true;
      state.errorMsg = false;
    },
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogged = false;
      state.errorMsg = true;
    },
    clearState(state, action) {
      return initialState;
    },
  },
});
export const {
  apiError,
  isFetching,
  fetchSuccess,
} = clientDashboardSlice.actions;

export const clientDashboardSelector = (state: RootState) => state.ClientDashboard;

export default clientDashboardSlice.reducer;