import React from "react";
import { Link } from "react-router-dom";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

const MonthlyEarning = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">
            Detalhamento da Economia Conquistada
            <i className="mdi mdi-information-outline p-1"></i>
          </CardTitle>
          <Row>
            <Col sm="6">
              <p className="text-muted">This month</p>
              <h3>$34,252</h3>
              <p className="text-muted">
                <span className="text-success me-2">
                  12% <i className="mdi mdi-arrow-up"></i>
                </span>
                From previous period
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MonthlyEarning;
