import React from "react";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";

import "./UserEditModal.styles.scss";

const UserEditModal = (props?: any) => {
  const { isOpen, toggle, editDetails } = props;

  const validation: any = useFormik({
    initialValues: {
      name: "",
      email: "",
      document: "",
      phone: "",
      zip: "",
      address: "",
      addressNumber: "",
      city: "",
      complement: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor, digite o nome completo."),
      document: Yup.string().required("Por favor, digite o documento."),
      email: Yup.string().email().required("Por favor, digite o email."),
      phone: Yup.string().required("Por favor, digite o telefone."),
      zip: Yup.string().required("Por favor, digite o CEP."),
      address: Yup.string().required("Por favor, digite o endereço."),
      addressNumber: Yup.string().required(
        "Por favor, digite o número do endereço."
      ),
      city: Yup.string().required("Por favor, digite a cidade."),
      complement: Yup.string().required("Por favor, digite a cidade."),
    }),
    onSubmit: (values: any) => {
      // console.log(values)
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="UserEditModal"
      tabIndex={-1}
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalBody>
          <Button
            type="button"
            className="btn-close_userEditModal"
            onClick={toggle}
          >
            X
          </Button>

          <Card>
            <CardBody className="info-user">
              <Row className="align-items-center p-3">
                <Col>
                  <h2 className="name-user">Christian Neller Michalszeszen</h2>
                  <p className="email-user">ch***er@gmail.com</p>
                </Col>
                <Col className="d-md-flex d-none align-items-center justify-content-end gap-5 details-user">
                  <div className="text-center">
                    <p className="title-detail">Telefone</p>
                    <p className="describe-detail">+55 99*****99</p>
                  </div>
                  <div className="text-center">
                    <p className="title-detail">CPF / CNPJ</p>
                    <p className="describe-detail">073.123.541-5</p>
                  </div>
                  <div className="text-center">
                    <p className="title-detail">Matrícula</p>
                    <p className="describe-detail">031a41g56u</p>
                  </div>
                </Col>
              </Row>
            </CardBody>

            <CardBody>
              <Row className="px-3">
                <h2 className="title-card">1. Dados do Beneficiário</h2>

                <Form onSubmit={validation.handleSubmit}>
                  <Row>
                    <Col>
                      <FormGroup className="mb-4">
                        <Label
                          htmlFor="user-edit-modal-name"
                          className="col-form-label"
                        >
                          Nome Completo
                        </Label>
                        <Input
                          type="text"
                          id="user-edit-modal-name"
                          placeholder="Digite o nome completo"
                          name="name"
                          value={validation.values.name}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mb-4">
                        <Label
                          htmlFor="user-edit-modal-document"
                          className="col-form-label"
                        >
                          CPF / CNPJ
                        </Label>
                        <Input
                          type="text"
                          id="user-edit-modal-document"
                          placeholder="Digite o documento"
                          name="document"
                          value={validation.values.document}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.document &&
                            validation.errors.document
                              ? true
                              : false
                          }
                        />
                        {validation.touched.document &&
                        validation.errors.document ? (
                          <FormFeedback type="invalid">
                            {validation.errors.document}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label
                          htmlFor="user-edit-modal-phone"
                          className="col-form-label"
                        >
                          Telefone
                        </Label>
                        <Col>
                          <Input
                            type="text"
                            id="user-edit-modal-phone"
                            placeholder="Digite o telefone"
                            name="phone"
                            value={validation.values.phone}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label
                          htmlFor="user-edit-modal-email"
                          className="col-form-label"
                        >
                          E-mail
                        </Label>
                        <Col>
                          <Input
                            type="email"
                            id="user-edit-modal-email"
                            placeholder="Digite o email"
                            name="email"
                            value={validation.values.email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label
                          htmlFor="user-edit-modal-zip"
                          className="col-form-label"
                        >
                          CEP
                        </Label>
                        <Col>
                          <Input
                            type="text"
                            id="user-edit-modal-zip"
                            placeholder="Digite o CEP"
                            name="zip"
                            value={validation.values.zip}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.zip && validation.errors.zip
                                ? true
                                : false
                            }
                          />
                          {validation.touched.zip && validation.errors.zip ? (
                            <FormFeedback type="invalid">
                              {validation.errors.zip}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label
                          htmlFor="user-edit-modal-city"
                          className="col-form-label"
                        >
                          Cidade
                        </Label>
                        <Col>
                          <Input
                            type="text"
                            id="user-edit-modal-city"
                            placeholder="Digite a cidade"
                            name="city"
                            value={validation.values.city}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.city && validation.errors.city
                                ? true
                                : false
                            }
                          />
                          {validation.touched.city && validation.errors.city ? (
                            <FormFeedback type="invalid">
                              {validation.errors.city}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label
                          htmlFor="user-edit-modal-address"
                          className="col-form-label"
                        >
                          Rua
                        </Label>
                        <Col>
                          <Input
                            type="text"
                            id="user-edit-modal-address"
                            placeholder="Digite o endereço"
                            name="address"
                            value={validation.values.address}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                          validation.errors.address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.address}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label
                          htmlFor="user-edit-modal-addressNumber"
                          className="col-form-label"
                        >
                          Número
                        </Label>
                        <Col>
                          <Input
                            type="text"
                            id="user-edit-modal-addressNumber"
                            placeholder="Digite o número do endereço"
                            name="addressNumber"
                            value={validation.values.addressNumber}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.addressNumber &&
                              validation.errors.addressNumber
                                ? true
                                : false
                            }
                          />
                          {validation.touched.addressNumber &&
                          validation.errors.addressNumber ? (
                            <FormFeedback type="invalid">
                              {validation.errors.addressNumber}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor="user-edit-modal-complement"
                          className="col-form-label"
                        >
                          Complemento
                        </Label>
                        <Col>
                          <Input
                            type="text"
                            id="user-edit-modal-complement"
                            placeholder="Digite o complemento"
                            name="complement"
                            value={validation.values.complement}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>

                  <hr className="mt-4 mb-4 divider" />

                  <h2 className="title-card">2. Créditos</h2>

                  <Row>
                    <Col md={6}>
                      <FormGroup className="recharge-value">
                        <Label>Saldo Livre</Label>
                        <div className="d-flex align-items-center">
                          <div className="recharge-value-symbol">R$</div>
                          <Input type="text" placeholder="00,00" />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="recharge-value">
                        <Label>Saldo Bloqueado</Label>
                        <div className="d-flex align-items-center">
                          <div className="recharge-value-symbol">R$</div>
                          <Input type="text" placeholder="00,00" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <button className="btn btn-save_userEditModal">Salvar</button>
                </Form>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default UserEditModal;
