import React, { useState } from "react";
import { useFormik } from "formik";
import classnames from "classnames";

import * as Yup from "yup";

import Breadcrumb from "Components/Common/Breadcrumb";
import FirstTab from "Components/finalClient/payAccount/firstTab";
import SecondTab from "Components/finalClient/payAccount/secondTab";
import ThirdTab from "Components/finalClient/payAccount/thirdTab";

import { Card, Col, Container, NavItem, NavLink, TabContent } from "reactstrap";

import "./payAccount.styles.scss";

const PayAccount = () => {
  document.title = "Pagar Conta de Energia | Instituto Global ESG";

  const [activeTab, setactiveTab] = useState<number>(1);
  const [passedSteps, setPassedSteps] = useState([1]);

  function toggleTab(tab: any) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  const wizardformik: any = useFormik({
    initialValues: {
      barcode: "",
    },
    validationSchema: Yup.object({
      barcode: Yup.string().required("Este campo é obrigatório"),
    }),

    onSubmit: (values: any) => {
      // console.log("value", values.password);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Home" breadcrumbItem="Pagar Conta de Energia" />

          <Col lg="12" className="d-flex justify-content-center">
            <Card className="pay-account-form_final-client">
              <div>
                <div className="wizard clearfix">
                  <div className="steps clearfix">
                    <ul>
                      <NavItem
                        className={classnames({ current: activeTab === 1 })}
                      >
                        <NavLink
                          className={classnames({ current: activeTab === 1 })}
                          onClick={() => {
                            setactiveTab(1);
                          }}
                          disabled={!(passedSteps || []).includes(1)}
                        >
                          <span className="number">1</span> Código do Boleto
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({ current: activeTab === 2 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 2 })}
                          onClick={() => {
                            setactiveTab(2);
                          }}
                          disabled={!(passedSteps || []).includes(2)}
                        >
                          <span className="number">2</span> Dados do Pagamento
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({ current: activeTab === 3 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 3 })}
                          onClick={() => {
                            setactiveTab(3);
                          }}
                          disabled={!(passedSteps || []).includes(3)}
                        >
                          <span className="number">3</span> Pagamento
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                  <div className="content clearfix">
                    <TabContent activeTab={activeTab} className="body">
                      <FirstTab
                        wizardformik={wizardformik}
                        toggleTab={toggleTab}
                        activeTab={activeTab}
                      />
                      <SecondTab toggleTab={toggleTab} activeTab={activeTab} />

                      <ThirdTab toggleTab={toggleTab} activeTab={activeTab} />
                    </TabContent>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PayAccount;
