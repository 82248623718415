//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_LOGIN = "/api/v1/auth/email/login";
export const POST_UPDATE_USER = "/api/v1/users/";

export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";



//CLIENT DASHBOARD
export const GET_CLIENT_DASHBOARD = "/api/dashboards/me";

//PARTNER DASHBOARD
export const GET_PARTNER_DASHBOARD = "/api/dashboards/partner/me";