import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import Breadcrumb from "Components/Common/Breadcrumb";
import TotalBalanceCard from "Components/finalClient/home/totalBalanceCard";
import Activity from "Components/finalClient/home/activity";

import "./dashboard.styles.scss";
import EnergyVoucher from "Components/finalClient/home/energyVoucher";
import { useAppDispatch, useAppSelector } from "slices";
import { clientDashboardSelector } from "slices/client/dashboard/reducer";
import { fetchData } from "slices/client/dashboard/thunk";

const Dashboard = () => {
  const dispatch: any = useAppDispatch();
  const { balance, myVouchers, totalCounters, simpleTransactionHistory } = useAppSelector(clientDashboardSelector)
  document.title = "Início | Instituto Global ESG";


  useEffect(() => {
    dispatch(fetchData(null))
  }, [])

  useEffect(() => {
    console.log(simpleTransactionHistory)
  }, [simpleTransactionHistory])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Home" breadcrumbItem="Painel do Usuário" />

          <div className="d-flex gap-5 flex-column flex-lg-row">
            <div className="col col-lg-4">
              <Row>
                <Col>
                  <TotalBalanceCard balance={balance.value} />
                </Col>
              </Row>

              <Row className="row-orders mt-4">
                <Col>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p>Total de Contas Pagas</p>
                          <h4 className="mb-0">{ totalCounters.totalBillsPaid }</h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div>
                        <div>
                          <p>Total Economizado</p>
                          <h4 className="mb-0">
                            {/* <span>R$</span> */}
                            { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(totalCounters?.totalBillsSaved || '0')) }
                          </h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row className="d-none d-lg-flex">
                <Col>
                  <Activity data={simpleTransactionHistory} />
                </Col>
              </Row>
            </div>

            <div className="col">
              <div className="d-flex space-between my-energy-vouchers">
                <div className="col-6">
                  <h5 className="mb-0">Meus Vales Energia</h5>
                </div>

                <div className="col-6 d-none d-lg-flex justify-content-end">
                  <h5 className="mb-0">Total: { myVouchers.length }</h5>
                </div>
              </div>

              <EnergyVoucher data={myVouchers} />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
