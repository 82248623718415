import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import "./totalBalanceCard.styles.scss";

const TotalBalanceCardPartnerCompany = ({ data }: any) => {
  const [hideBalance, setHideBalance] = useState(false);
  return (
    <React.Fragment>
      <Card className="total-balance-card_partner-company">
        <CardBody>
          <CardTitle className="mb-3">Saldo Total</CardTitle>
          <Row>
            <Col>
              <p className="text-muted">Este mês</p>
              <h3 className="balance">
                {/* <span>R$</span> */}
                {hideBalance ? "---------" : Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.value)}
                <button
                  className="btn btn-sm btn-hide-balance"
                  onClick={() => setHideBalance(!hideBalance)}
                >
                  {hideBalance ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </button>
              </h3>
              {/* <p className="text-muted">
                <span className="text-success me-2">
                  12% <i className="mdi mdi-arrow-up"></i>
                </span>
                desde setembro
              </p> */}
              <div className="d-flex gap-2 mt-4">
                {/* <div>
                  <Link
                    to="#"
                    className="btn btn-primary waves-effect waves-light btn-sm btn-addept-to-vale"
                  >
                    Ver histórico
                    <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div> */}
                <div className="d-lg-none">
                  <Link
                    to="#"
                    className="btn btn-primary waves-effect waves-light btn-sm btn-addept-to-vale"
                  >
                    Atividades recentes{" "}
                    <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div className="w-100 d-flex flex-column gap-1">
        <Link to="/partnerCompany/emitir-vale-energia" className="btn-total-balance-card_partner-company">
          Emitir Vale Energia
          <i className="mdi mdi-text-box-plus"></i>
        </Link>
        <Link to="/partnerCompany/recarregar-saldo" className="btn-total-balance-card_partner-company d-none d-lg-flex">
          Recarregar Saldo
          <i className="mdi mdi-currency-usd-circle-outline"></i>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default TotalBalanceCardPartnerCompany;
