import React from "react";
import { Link } from "react-router-dom";

import { Form, Row, TabPane } from "reactstrap";

import SelectMethodPayment from "Components/finalClient/selectMethodPayment";

import EnelLogo from "./enel_logo.svg";
import YamanLogo from "./logo_yaman.svg";

import "./secondTab.styles.scss";

const SecondTab = ({ toggleTab, activeTab }) => {
  return (
    <TabPane tabId={2}>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <Form className="second-tab-payment col-xl-8">
          <h2>
            Você está economizando <span>36,05%</span>
            <i className="mdi mdi-information-outline"></i>
          </h2>

          <Row className="w-100 pt-3 text-start">
            <h4>Parceiros e saldos</h4>

            <div className="card-partners-and-balances">
              <div className="d-flex col">
                <div className="card-partners-and-balances_logo">
                  <img src={EnelLogo} alt="Logo da Enel" />
                </div>
                <div className="card-partners-and-balances_name">
                  <p>Empresa 01</p>
                </div>
              </div>

              <div className="card-partners-and-balances_value">R$ 10,00</div>
            </div>

            <div className="card-partners-and-balances">
              <div className="d-flex col">
                <div className="card-partners-and-balances_logo">
                  <img src={YamanLogo} alt="Logo da Yaman" />
                </div>
                <div className="card-partners-and-balances_name">
                  <p>Empresa 01</p>
                </div>
              </div>

              <div className="card-partners-and-balances_value">R$ 9,00</div>
            </div>
          </Row>

          <Row className="w-100 gap-2 pt-3 text-start">
            <h4 className="mb-4">Resumo da Fatura</h4>

            <div className="card-invoice-summary">
              <p>Beneficiário</p>
              <p>COPEL DISTRIBUIÇÃO S.A.</p>
            </div>
            <div className="card-invoice-summary">
              <p>Valor da conta de luz</p>
              <p>R$ 52,50</p>
            </div>
            <div className="card-invoice-summary">
              <p>Descontos</p>
              <p> -R$19,00</p>
            </div>
            <div className="card-invoice-summary">
              <p>Forma de Pagamento</p>
              <p>PIX</p>
            </div>

            <div className="card-invoice-total">
              <p>Total a pagar:</p>
              <p>R$ 33,50</p>
            </div>
          </Row>

          {/* <Row className="w-100 pt-3 text-start">
            <h4 className="mb-4">Métodos de Pagamento</h4>

            <SelectMethodPayment />
          </Row> */}

          <div className="actions clearfix w-100 second-tab-payment_actions">
            <Link
              to="#"
              className="previous"
              onClick={() => {
                toggleTab(activeTab - 1);
              }}
            >
              Voltar
            </Link>
            <Link
              to="#"
              className="button-continue"
              onClick={() => {
                toggleTab(activeTab + 1);
              }}
            >
              Pagar
            </Link>
          </div>
        </Form>
      </div>
    </TabPane>
  );
};

export default SecondTab;
