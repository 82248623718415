import React from "react";
import { Navigate } from "react-router-dom";
import { authSelector } from "slices/auth/reducer";
import { useAppSelector } from "slices";

const AuthProtected = (props) => {
  const { isUserLogged } = useAppSelector(authSelector);

  if (!isUserLogged) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AuthProtected;
