import { useState } from "react";
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "Components/ChartDynamicColor";

import { Card, CardBody, Col, Row } from "reactstrap";

import { visitor } from "./data";

interface series {
  name: string;
  data: number[];
}

const ProjectionOfFutureVouchers = ({ dataColors }: any) => {
  const apexCardUserChartColors = getChartColorsArray(dataColors);

  const [activeTab, setActivetab] = useState<number>(1);

  const visitors: any = visitor[0] || [];

  const options: any = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: apexCardUserChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  };

  const series: series[] = [
    {
      name: "Current",
      data: visitors?.data?.Currentdata || [],
    },
    {
      name: "Previous",
      data: visitors?.data?.Previousdata || [],
    },
  ];

  console.log(visitors);

  const handleChangeChartData = (id: number) => {
    setActivetab(id);
  };

  return (
    <Card>
      <CardBody>
        <div className="d-flex flex-wrap">
          <h5 className="card-title me-2">Projeção de Vales futuros</h5>
          <div className="ms-auto">
            <div className="toolbar d-flex flex-wrap gap-2 text-end">
              <button
                type="button"
                className={`btn btn-light btn-sm ${
                  activeTab === 1 && "active"
                }`}
                onClick={() => handleChangeChartData(1)}
              >
                ALL
              </button>
              <button
                type="button"
                className={`btn btn-light btn-sm ${
                  activeTab === 2 && "active"
                }`}
                onClick={() => handleChangeChartData(2)}
              >
                1M
              </button>
              <button
                type="button"
                className={`btn btn-light btn-sm ${
                  activeTab === 3 && "active"
                }`}
                onClick={() => handleChangeChartData(3)}
              >
                6M
              </button>
              <button
                type="button"
                className={`btn btn-light btn-sm ${
                  activeTab === 4 && "active"
                }`}
                onClick={() => handleChangeChartData(4)}
              >
                1Y
              </button>
            </div>
          </div>
        </div>

        <Row className="text-start">
          <Col lg={1}>
            <div className="mt-4">
              <p className="text-muted mb-1">Today</p>
              <h5>{visitors.today}</h5>
            </div>
          </Col>

          <Col lg={1}>
            <div className="mt-4">
              <p className="text-muted mb-1">This Month</p>
              <h5>
                {visitors.thisMonth}
                <span className="text-success font-size-13">
                  {" "}
                  0.2 % <i className="mdi mdi-arrow-up ms-1"></i>
                </span>
              </h5>
            </div>
          </Col>

          <Col lg={1}>
            <div className="mt-4">
              <p className="text-muted mb-1">This Year</p>
              <h5>
                {visitors.thisYear}
                <span className="text-success font-size-13">
                  {" "}
                  0.1 % <i className="mdi mdi-arrow-up ms-1"></i>
                </span>
              </h5>
            </div>
          </Col>
        </Row>

        <hr className="mb-4" />
        <div id="area-chart" dir="ltr">
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={350}
            className="apex-charts"
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default ProjectionOfFutureVouchers;
