import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

import ExpandSharpIcon from "@mui/icons-material/ExpandSharp";

import "./activities.styles.scss";

const Activity = ({ data }: any) => {
  return (
    <React.Fragment>
      <Card className="activities-card">
        <CardBody>
          <CardTitle className="mb-5">Atividades Recentes</CardTitle>
          <ul className="verti-timeline list-unstyled">
            {
              data && (data.length > 0) && data.map((activity: any) => (
                <li className="event-list" key={activity.id}>
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle font-size-18" />
                  </div>
                  <div className="flex-shrink-0 d-flex">
                    <div className="me-3 d-flex">
                      <h5 className="font-size-14 w-60">Hoje</h5>
                      <div className="expand-icon">
                        <ExpandSharpIcon />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <div>
                        {activity.description}
                        {/* <span>Conta de luz #000324</span> em aprovação. */}
                      </div>
                    </div>
                  </div>
                </li>
              ))
            }

            {/* <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <div className="d-flex">
                <div className="me-3 d-flex">
                  <h5 className="font-size-14 w-50">17 Nov</h5>
                  <div className="expand-icon">
                    <ExpandSharpIcon />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div id="activitytext">
                    Novo vale Energia!{" "}
                    <Link to="#" className="link-to-view-vale">
                      Ver vale
                    </Link>
                  </div>
                </div>
              </div>
            </li>

            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <div className="d-flex">
                <div className="me-3 d-flex">
                  <h5 className="font-size-14 w-50">15 Nov</h5>
                  <div className="expand-icon">
                    <ExpandSharpIcon />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div>
                    <span>Conta de luz #000323</span> paga com sucesso.
                  </div>
                </div>
              </div>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <div className="d-flex">
                <div className="me-3 d-flex">
                  <h5 className="font-size-14 w-50">12 Nov</h5>
                  <div className="expand-icon">
                    <ExpandSharpIcon />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div>
                    <span>Conta de luz #000323</span> em aprovação.
                  </div>
                </div>
              </div>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <div className="d-flex">
                <div className="me-3 d-flex">
                  <h5 className="font-size-14 w-50">3 Nov</h5>
                  <div className="expand-icon">
                    <ExpandSharpIcon />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div>Conta criada.</div>
                </div>
              </div>
            </li> */}
          </ul>
          <div className="text-center mt-4">
            <Link
              to="/client/historic"
              className="btn btn-primary waves-effect waves-light btn-sm btn-view-more"
            >
              Ver Tudo <i className="mdi mdi-arrow-right ms-1" />
            </Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Activity;
