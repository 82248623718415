import React from "react";

import { Button, Card, Modal, ModalBody } from "reactstrap";

import EnergyVoucherEdit from "./EnergyVoucherEdit";

import "./EnergyVoucherModal.styles.scss";

const EnergyVoucherModal = (props?: any) => {
  const { isOpen, toggle, editDetails } = props;

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="EnergyVoucherEdit"
      tabIndex={-1}
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalBody>
          <Button
            type="button"
            className="btn-close_userEditModal"
            onClick={toggle}
          >
            X
          </Button>

          <Card className="p-5">
            <EnergyVoucherEdit />
          </Card>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default EnergyVoucherModal;
