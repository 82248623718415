import React from "react";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

const DistributionOfVouchers = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Distribuição dos Vales</CardTitle>
          <Row>
            <Col>
              <p className="text-muted">This month</p>
              <h3>$34,252</h3>
              <div className="d-flex">
                <p className="text-muted">
                  <span className="text-success me-2">
                    12% <i className="mdi mdi-arrow-up"></i>
                  </span>
                  From previous period
                </p>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default DistributionOfVouchers;
