import React, { useEffect, useMemo, useState } from "react";

import { Col, Container, Row } from "reactstrap";

import Breadcrumb from "Components/Common/Breadcrumb";

import TableContainer from "Components/Common/TableContainer";

import { Idno, Pdate, Type, Value, ValueInUsd, Amount } from "./CryptoCol";

import { cryptoOrderData } from "./data";

import "./activities.styles.scss";

const Activities = () => {
  document.title = "Atividades | Instituto Global ESG";

  const [order, setOrder] = useState<any>(cryptoOrderData);

  const columns = useMemo(
    () => [
      {
        Header: "Id No",
        accessor: "id",
        filterable: true,
        Filter: false,
        isSortable: true,
        Cell: (cellProps: any) => {
          return <Idno {...cellProps} />;
        },
      },
      {
        Header: "Date",
        accessor: "date",
        filterable: true,
        Filter: false,
        isSortable: true,
        Cell: (cellProps) => {
          return <Pdate {...cellProps} />;
        },
      },
      {
        Header: "Type",
        accessor: "type",
        filterable: true,
        Filter: false,
        isSortable: true,
        Cell: (cellProps: any) => {
          return <Type {...cellProps} />;
        },
      },
      {
        Header: "Currency",
        accessor: "currency",
        filterable: true,
        Filter: false,
        isSortable: true,
        Cell: (cellProps: any) => {
          return <Value {...cellProps} />;
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        filterable: true,
        Filter: false,
        isSortable: true,
        Cell: (cellProps: any) => {
          return <Amount {...cellProps} />;
        },
      },
      {
        Header: "Amount in USD",
        accessor: "amountinUSD",
        filterable: true,
        Filter: false,
        isSortable: false,
        Cell: (cellProps: any) => {
          return <ValueInUsd {...cellProps} />;
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Painel do Usuário" breadcrumbItem="Atividades" />

          <Row>
            <Col>
              <div className="activities-table">
                <TableContainer
                  columns={columns}
                  data={order || []}
                  isGlobalFilter={true}
                  customPageSizeOption={true}
                  customPageSize={10}
                  isPagination={true}
                  isShowingPageLength={true}
                  paginationDiv="col-sm-12 col-md-7"
                  pagination="pagination justify-content-end"
                  tableClass="table-hover datatable dt-responsive nowrap dataTable no-footer dtr-inline"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Activities;
