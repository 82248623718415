import { Navigate } from "react-router-dom";
//Client Routes
import Dashboard from "../pages/Client/Dashboard";
import PayAccount from "../pages/Client/PayAccount";
import PaymentHistory from "../pages/Client/PaymentHistory";

// Auth
import Login from "pages/Authentication/login";
import Logout from "pages/Authentication/Logout";
import UserProfile from "pages/Authentication/user-profile";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import SignUp from "pages/Authentication/Register";

import Perfil from "../pages/Client/Perfil";
import Activities from "pages/Client/Activities";
import Historic from "pages/Client/Historic";
import Support from "pages/Client/Support";
import TermsOfUse from "pages/Client/termsOfUse";
import Notifications from "pages/Client/Notifications";
import DashboardPartnerCompany from "pages/PartnerCompany/Dashboard";
import ActivitiesPartnerCompany from "pages/PartnerCompany/Activities";
import Reports from "pages/PartnerCompany/Reports";
import RechargeBalance from "pages/PartnerCompany/RechargeBalance";
import IssueEnergyVoucher from "pages/PartnerCompany/IssueEnergyVoucher";
import PerfilPartnerCompany from "pages/PartnerCompany/Perfil";
import UserManagement from "pages/PartnerCompany/UserManagement";
import SearchUser from "pages/PartnerCompany/SearchUser";
import SearchEnergyVoucher from "pages/PartnerCompany/SearchEnergyVoucher";
import ConfigEmission from "pages/PartnerCompany/ConfigEmission";

const clientRoutes = [
  { path: "/", exact: true, component: <Navigate to="/client/dashboard" /> },

  { path: "/client/dashboard", component: <Dashboard /> },
  { path: "/client/payment", component: <PayAccount /> },
  { path: "/client/payment-history", component: <PaymentHistory /> },
  { path: "/profile", component: <Perfil /> },
  { path: "/client/activities", component: <Activities /> },
  { path: "/client/historic", component: <Historic /> },
  { path: "/client/support", component: <Support /> },
  { path: "/client/terms-of-use", component: <TermsOfUse /> },
  { path: "/client/notifications", component: <Notifications /> },
];

const partnerRoutes = [
  {
    path: "/",
    exact: true,
    component: <Navigate to="/partnerCompany/dashboard" />,
  },

  { path: "/partnerCompany/dashboard", component: <DashboardPartnerCompany /> },
  { path: "/partnerCompany/reports", component: <Reports /> },
  { path: "/partnerCompany/recarregar-saldo", component: <RechargeBalance /> },
  {
    path: "/partnerCompany/emitir-vale-energia",
    component: <IssueEnergyVoucher />,
  },
  { path: "/partnerCompany/perfil", component: <PerfilPartnerCompany /> },
  {
    path: "/partnerCompany/activities",
    component: <ActivitiesPartnerCompany />,
  },
  {
    path: "/partnerCompany/criar-usuario",
    component: <UserManagement />,
  },
  {
    path: "/partnerCompany/procurar-usuario",
    component: <SearchUser />,
  },
  {
    path: "/partnerCompany/procurar-vale-de-energia",
    component: <SearchEnergyVoucher />,
  },
  {
    path: "/partnerCompany/configurar-emissao",
    component: <ConfigEmission />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <SignUp /> },
];

export { clientRoutes, partnerRoutes, publicRoutes };
