import React from "react";
import { Link } from "react-router-dom";

import { Col, Form, FormGroup, Input, Label, Row, TabPane } from "reactstrap";

import SelectMethodPayment from "Components/finalClient/selectMethodPayment";

import "./firstTab.styles.scss";

const FirstTab = ({ wizardformik, toggleTab, activeTab }) => {
  return (
    <TabPane tabId={1}>
      <Form
        onSubmit={wizardformik.handleSubmit}
        className="d-flex flex-column align-items-center"
      >
        <div className="first-tab-recharge">
          <Row>
            <Col>
              <FormGroup className="current-balance">
                <Label>Saldo Atual</Label>
                <p className="m-0 mt-2">R$ 1.406.278,00</p>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="recharge-value">
                <Label>Valor da Recarga</Label>
                <div className="d-flex align-items-center">
                  <div className="recharge-value-symbol">R$</div>
                  <Input type="text" />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row className="resume-bills">
            <h3>Resumo da Fatura</h3>
            <div className="resume-bills-item">
              <p>Valor Solicitado</p>
              <p>R$ 1.312.000,00</p>
            </div>
            <div className="resume-bills-item">
              <p>Forma de Pagamento</p>
              <p>PIX</p>
            </div>
            <div className="resume-bills-item total">
              <p>Valor final da recarga</p>
              <p>R$ 1.312.000,00</p>
            </div>
          </Row>

          {/* <Row className="pt-5 text-start">
            <h4 className="mb-4">Métodos de Pagamento</h4>

            <SelectMethodPayment />
          </Row> */}

          <Row className="actions clearfix">
            <Link
              to="#"
              className={
                wizardformik.errors.barcode
                  ? "button-continue disabled"
                  : "button-continue"
              }
              onClick={() => {
                toggleTab(activeTab + 1);
              }}
            >
              Continuar
            </Link>
          </Row>
        </div>
      </Form>
    </TabPane>
  );
};

export default FirstTab;
