import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import Dropzone from "react-dropzone";

import EnergyVoucher from "Components/finalClient/home/energyVoucher";

import {
  Row,
  Col,
  Input,
  TabPane,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  InputGroup,
} from "reactstrap";

import "./IndividualConfig.styles.scss";

const customStylesMonthSelect = {
  container: (provided) => ({
    ...provided,
    position: "relative",

    maxWidth: "300px",
    height: "2rem",
  }),
  control: (provided) => ({
    ...provided,
    height: "100%",
  }),

  menu: (provided) => ({
    ...provided,
  }),

  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#333",
    padding: 8,
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
  }),
  icon: {
    marginRight: 12,
  },
};

const numbers = [
  { value: "1", label: "3 meses" },
  { value: "2", label: "6 meses" },
  { value: "3", label: "12 meses" },
];

const IndividualConfig = () => {
  const [selectedFiles, setselectedFiles] = useState<any>([]);

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  const validation: any = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
      country: "",
      states: "",
      order: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor, digite seu nome."),
      email: Yup.string().email().required("Por favor, digite seu email."),
      phone: Yup.string().required("Por favor, digite seu telefone."),
    }),
    onSubmit: (values: any) => {
      // console.log(values)
    },
  });

  return (
    <TabPane tabId="1" className="configEmissionTab">
      <h2 className="title-tab_issueEnergyVoucher">1. Configurações Gerais</h2>

      <Row>
        <Col>
          <FormGroup className="recharge-value">
            <Label md="2">
              Valor <br /> Mínimo
            </Label>
            <div className="d-flex align-items-center w-100">
              <div className="recharge-value-symbol">R$</div>
              <Input type="text" placeholder="00,00" />
            </div>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="recharge-value">
            <Label md="2">
              Valor <br /> Máximo
            </Label>
            <div className="d-flex align-items-center w-100">
              <div className="recharge-value-symbol">R$</div>
              <Input type="text" placeholder="00,00" />
            </div>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup row className="align-items-center">
            <Label md="2">
              Data de
              <br /> Início
            </Label>
            <Col>
              <InputGroup className="datepicker-individualconfig-group">
                <Flatpickr
                  className="form-control d-block datepicker-individualconfig"
                  placeholder="Selecione a Data"
                  options={{
                    altInput: true,
                    dateFormat: "d-m-Y",
                    locale: Portuguese,
                  }}
                  translate="yes"
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-outline-secondary docs-datepicker-trigger"
                    disabled
                  >
                    <i className="fa fa-calendar" aria-hidden="true" />
                  </button>
                </div>
              </InputGroup>
            </Col>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup row className="align-items-center">
            <Label md="2">
              Data de
              <br /> Término
            </Label>
            <Col>
              <InputGroup className="datepicker-individualconfig-group">
                <Flatpickr
                  className="form-control d-block datepicker-individualconfig"
                  placeholder="Selecione a Data"
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "d-m-Y",
                    locale: Portuguese,
                  }}
                  translate="yes"
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-outline-secondary docs-datepicker-trigger"
                    disabled
                  >
                    <i className="fa fa-calendar" aria-hidden="true" />
                  </button>
                </div>
              </InputGroup>
            </Col>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <Label>Logomarca (.JPG, .PNG, máx 2MB)</Label>
          <Dropzone
            onDrop={(acceptedFiles: any) => {
              handleAcceptedFiles(acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps }: any) => (
              <div className="dropzone">
                <div className="dz-message needsclick mt-2" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Arraste aqui ou clique para escolher.</h4>
                </div>
              </div>
            )}
          </Dropzone>
        </Col>
      </Row>

      <hr className="mt-4 mb-4 divider" />

      <div>
        <h2 className="title-tab_issueEnergyVoucher mt-3">2. Expiração</h2>

        <FormGroup className="mb-4" row>
          <Label md="2" class Name="col-form-label">
            Tempo de Expiração
          </Label>
          <Col>
            <Select
              options={numbers}
              styles={customStylesMonthSelect}
              placeholder="QTD"
              components={{
                SingleValue: ({ data, ...props }) => {
                  return <div {...props}>{data?.label}</div>;
                },
              }}
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </Col>
        </FormGroup>
      </div>

      <hr className="mt-4 mb-4 divider" />

      <Row>
        <h2 className="title-tab_issueEnergyVoucher mt-3">3. Alertas</h2>

        <Col>
          <FormGroup className="form-check form-switch mb-3 form-switch_individualConfig">
            <Label className="col-form-label" md={4}>
              Usar Alertas
            </Label>
            <input
              type="checkbox"
              className="form-check-input switch_individualConfig"
              id="customSwitchsizemd"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <p>Enviar alertas para os seguintes emails (separar por vírgula)</p>

          <FormGroup>
            <Input type="textarea" id="textarea" maxLength={225} rows="3" />
          </FormGroup>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <button className="btn btn-saveConfigEmission">Salvar</button>
        </Col>
      </Row>
    </TabPane>
  );
};

export default IndividualConfig;
