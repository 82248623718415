import React, { useCallback, useMemo, useState } from "react";

import { Button, Card, CardBody, CardTitle } from "reactstrap";

import EnergyVoucherModal from "./EnergyVoucherModal";
import TableContainer from "Components/Common/TableContainer";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LatestTransactionCol";

import "./transactionsTable.styles.scss";

const latestTransactionData = [
  {
    orderId: "#SK2545",
    billingName: "Jacob Hunter",
    orderdate: "04 Oct, 2019",
    total: "$392",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    orderId: "#SK2544",
    billingName: "Ronald Taylor",
    orderdate: "04 Oct, 2019",
    total: "$404",
    paymentStatus: "Refund",
    methodIcon: "fab fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    orderId: "#SK2543",
    billingName: "Barry Dick",
    orderdate: "05 Oct, 2019",
    total: "$412",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    orderId: "#SK2542",
    billingName: "Juan Mitchell",
    orderdate: "06 Oct, 2019",
    total: "$384",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    orderId: "#SK2541",
    billingName: "Jamal Burnett",
    orderdate: "07 Oct, 2019",
    total: "$380",
    paymentStatus: "Chargeback",
    methodIcon: "fab fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "07 Oct, 2019",
    total: "$400",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "07 Oct, 2019",
    total: "$400",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "07 Oct, 2019",
    total: "$400",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "07 Oct, 2019",
    total: "$400",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
];

const SearchResults = () => {
  const [modal1, setModal1] = useState<boolean>(false);
  const [editDetails, setEditDetails] = useState<any>("");
  const toggleViewModal = useCallback(
    (value: any) => {
      setModal1(!modal1);
      setEditDetails(value);
    },
    [modal1]
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: (cellProps: any) => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Order ID",
        accessor: "orderId",
        filterable: false,
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          return <OrderId {...cellProps} />;
        },
      },
      {
        Header: "Billing Name",
        accessor: "billingName",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Date",
        accessor: "orderdate",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Total",
        accessor: "total",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: "Payment Status",
        accessor: "paymentStatus",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <PaymentStatus {...cellProps} />;
        },
      },
      {
        Header: "Payment Method",
        accessor: "paymentMethod",
        disableFilters: true,
        Filter: false,
        Cell: (cellProps: any) => {
          return <PaymentMethod {...cellProps} />;
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Filter: false,
        Cell: (cellProps: any) => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => toggleViewModal(cellProps.cell.row.original)}
            >
              {" "}
              View Details{" "}
            </Button>
          );
        },
      },
    ],
    [toggleViewModal]
  );

  return (
    <React.Fragment>
      <EnergyVoucherModal
        isOpen={modal1}
        toggle={toggleViewModal}
        editDetails={editDetails}
      />
      <Card className="transactions-table">
        <CardBody>
          <CardTitle tag="h4" className="mb-4">
            Resultados da pesquisa
          </CardTitle>
          <TableContainer
            columns={columns}
            data={latestTransactionData}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={6}
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SearchResults;
