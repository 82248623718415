import { useState } from "react";

import Dropzone from "react-dropzone";

import { Row, TabPane, Col, Table } from "reactstrap";

import "./CreateUserInBulk.styles.scss";

const CreateUserInBulk = () => {
  const [selectedFiles, setselectedFiles] = useState<any>([]);

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  return (
    <TabPane tabId="2" className="createUserInBulkTab">
      <Col>
        <h2 className="title-tab_userManagement">
          1. Faça download da planilha de emissão em lote
        </h2>

        <div>
          <button className="btn btn-download-spreadsheet-createUserInBulk">
            <i className="mdi mdi-file-download-outline"></i>
            Download da planilha .xlsx
          </button>
        </div>

        <hr className="mt-4 mb-4 divider" />

        <div className="createUserInBulk_description">
          <h2 className="title-tab_userManagement mt-3 mb-4">
            2. Preencha o arquivo segundo as orientações abaixo:
          </h2>

          <Row>
            <Col xl={4}>
              <h4>Campos obrigatórios:</h4>

              <ul>
                <li>Nome Completo / Razão Social</li>
                <li>CPF / CNPJ (somente números)</li>
                <li>E-mail</li>
              </ul>
            </Col>
            <Col xl={4}>
              <h4>Importante:</h4>

              <ul>
                <li>
                  Não modifique a estrutura da planilha como linhas, colunas, ou
                  títulos
                </li>
                <li>Limite máximo de 2mb por arquivo</li>
              </ul>
            </Col>
          </Row>
        </div>

        <hr className="mt-4 mb-4 divider" />

        <div>
          <h2 className="title-tab_userManagement mt-3">
            3. Envie a planilha preenchida
          </h2>

          <Dropzone
            onDrop={(acceptedFiles: any) => {
              handleAcceptedFiles(acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps }: any) => (
              <div className="dropzone">
                <div className="dz-message needsclick mt-2" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Arraste aqui ou clique para escolher.</h4>
                </div>
              </div>
            )}
          </Dropzone>
        </div>

        <button className="btn btn-continueCreateUserInBulk">Continuar</button>
      </Col>

      <Col>
        <div className="table-responsive">
          <h2 className="title-tab_userManagement">
            Resumo do arquivo enviado:
          </h2>
          <Table className="table mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td style={{ color: "#7FBB9F" }}>Cadastro Completo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td style={{ color: "#F46A6A" }}>Cadastro Incompleto</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry</td>
                <td>the Bird</td>
                <td style={{ color: "#F46A6A" }}>Cadastro Incompleto</td>
              </tr>
            </tbody>
          </Table>

          <div className="d-flex justify-content-between gap-4">
            <Col>
              <button className="btn btn-backCreateUserInBulk">Voltar</button>
            </Col>
            <Col>
              <button className="btn btn-continueCreateUserInBulk">
                Criar Usuários
              </button>
            </Col>
          </div>
        </div>
      </Col>
    </TabPane>
  );
};

export default CreateUserInBulk;
