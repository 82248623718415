import React from "react";

import { Col } from "reactstrap";

import Logo from "./logo.svg";

import Leaf from "assets/images/psychiatry.svg";

import "./energyVoucher.styles.scss";

interface EnergyVoucherProps {
  data?: any;
}

const EnergyVoucher = ({ data }: EnergyVoucherProps) => {
  return (
    <React.Fragment>
      {data && (data.length > 0) ? (
        <>
          <div className="d-none d-lg-flex">
            {
              data.map((voucher: any) => (
                <div className="energy-voucher-card" key={voucher.id}>
                <Col
                  xl={8}
                  className="d-flex flex-column justify-content-between col-left"
                >
                  <div className="energy-voucher-card_header">
                    <h3>Vale Energia Patrocinado</h3>
                  </div>

                  <div className="energy-voucher-card_logo">
                    <img src='https://i.ibb.co/bs26DsC/Grupo-Arnone-Logomarca-01.png' alt="Logo" />
                  </div>

                  <div className="energy-voucher-card_expiration">
                    Expira em 90 Dias
                  </div>
                </Col>
                <Col className="d-flex align-items-center justify-content-center energy-voucher-card_value">
                  {/* <span>R$</span> */}
                  { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(voucher.value || '0')) }
                </Col>
              </div>
              ))
            }
          </div>

          <div className="d-flex d-lg-none flex-column">
            {
              data.map((voucher : any) => (
                <div className="energy-voucher-card mobile" key={voucher.id}>
                  <Col>
                    <div className="energy-voucher-card_value">
                      {/* <span>R$</span> */}
                      { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(voucher.value || '0')) }
                    </div>
                    <div className="energy-voucher-card_expiration">
                      Expira em 90 Dias
                    </div>
                  </Col>
                  <Col className="energy-voucher-card_logo">
                    <img src='https://i.ibb.co/bs26DsC/Grupo-Arnone-Logomarca-01.png' alt="Logo" />
                  </Col>
                </div>
              ))
            }
          </div>
        </>
      ) : (
        <div className="empty-energy-voucher">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M440-120v-319q-64 0-123-24.5T213-533q-45-45-69-104t-24-123v-80h80q63 0 122 24.5T426-746q31 31 51.5 68t31.5 79q5-7 11-13.5t13-13.5q45-45 104-69.5T760-720h80v80q0 64-24.5 123T746-413q-45 45-103.5 69T520-320v200h-80Zm0-400q0-48-18.5-91.5T369-689q-34-34-77.5-52.5T200-760q0 48 18 92t52 78q34 34 78 52t92 18Zm80 120q48 0 91.5-18t77.5-52q34-34 52.5-78t18.5-92q-48 0-92 18.5T590-569q-34 34-52 77.5T520-400Zm0 0Zm-80-120Z" />
          </svg>

          <p>
            Nenhum Vale Energia Cadastrado.
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

export default EnergyVoucher;
