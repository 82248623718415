import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    refreshToken: "",
    token: "",
    tokenExpires: null,
    user: {
      id: null,
      email: "admin@example.com",
      provider: "email",
      socialId: null,
      firstName: "Super",
      lastName: "Admin",
      createdAt: "2023-12-15T04:53:18.444Z",
      updatedAt: "2023-12-15T04:53:18.444Z",
      deletedAt: null,
      photo: null,
      role: {
        id: 1,
        name: "Admin",
        __entity: "Role"
    },
    status: {
    id: 1,
    name: "Active",
    __entity: "Status"
    },
    __entity: "User",
    },

    
    email: "",
    error: "",// for error msg
    loading: false,
    isUserLogout: false,
    errorMsg: false,// for error
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        loginSuccess(state, action) {

            state.email = action.payload
            state.loading = false;
            state.errorMsg = false;
        },
        apiError(state, action) {
            state.error = action.payload;
            state.loading = true;
            state.isUserLogout = false;
            state.errorMsg = true;
        },
        resetLoginFlag(state) {
            // state.error = null;
            state.error = "";
            state.loading = false;
            state.errorMsg = false;
        },
        logoutUserSuccess(state, action) {
            state.isUserLogout = true
        },
    }
});
export const { loginSuccess, apiError, resetLoginFlag, logoutUserSuccess } = loginSlice.actions;
export default loginSlice.reducer;