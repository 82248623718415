import React, { useEffect } from "react"
import withRouter from "Components/Common/withRouter";
import { Navigate } from "react-router-dom";

import { logoutUser } from "../../slices/thunk";

//redux
import { useAppDispatch, useAppSelector } from "slices";
import { authSelector } from "slices/auth/reducer";

const Logout = (props: any) => {
  const dispatch: any = useAppDispatch();

  const { isUserLogged } = useAppSelector(authSelector);

  useEffect(() => {
    dispatch(logoutUser(props.router.navigate));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (isUserLogged) {
    return <Navigate to="/login" />;
  }

  return <></>;
}


export default withRouter(Logout)
