import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import "./totalBalanceCard.styles.scss";

const TotalBalanceCard = (props: any) => {
  const [hideBalance, setHideBalance] = useState(false);
  return (
    <React.Fragment>
      <Card className="total-balance-card">
        <CardBody>
          <CardTitle className="mb-3">Saldo Total</CardTitle>
          <Row>
            <Col>
              <p className="text-muted">Este mês</p>
              <h3 className="balance">
                {/* <span>R$</span> */}
                {hideBalance ? "---------" : Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.balance)}
                <button
                  className="btn btn-sm btn-hide-balance"
                  onClick={() => setHideBalance(!hideBalance)}
                >
                  {hideBalance ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </button>
              </h3>
              <p className="text-muted">
                <span className="text-success me-2">
                  12% <i className="mdi mdi-arrow-up"></i>
                </span>
                desde setembro
              </p>
              <div className="d-flex gap-2 mt-4">
                <div>
                  <Link
                    to="#"
                    className="btn btn-primary waves-effect waves-light btn-sm btn-addept-to-vale"
                  >
                    Aderir ao Vale Energia{" "}
                    <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
                <div className="d-lg-none">
                  <Link
                    to="#"
                    className="btn btn-primary waves-effect waves-light btn-sm btn-addept-to-vale"
                  >
                    Atividades recentes{" "}
                    <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div className="w-100">
        <Link to="/client/payment" className="btn-pay-account">
          Pagar Conta
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              d="M0.992188 22.8045V17.6588H2.8227V20.974H6.13797V22.8045H0.992188ZM19.8852 22.8045V20.974H23.2005V17.6588H25.031V22.8045H19.8852ZM4.13242 19.6934V6.26011H6.33521V19.6934H4.13242ZM7.42965 19.6934V6.26011H8.57826V19.6934H7.42965ZM10.7811 19.6934V6.26011H12.988V19.6934H10.7811ZM14.1297 19.6934V6.26011H17.4338V19.6934H14.1297ZM18.5394 19.6934V6.26011H19.688V19.6934H18.5394ZM20.7824 19.6934V6.26011H21.8796V19.6934H20.7824ZM0.992188 8.30594V3.16016H6.13797V4.99066H2.8227V8.30594H0.992188ZM23.2005 8.30594V4.99066H19.8852V3.16016H25.031V8.30594H23.2005Z"
              fill="white"
            />
          </svg>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default TotalBalanceCard;
