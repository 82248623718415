import { getFirebaseBackend } from "helpers/firebase_helper";
import { postJwtLogin, postUpdateUser } from "helpers/fakebackend_helper";
import {
  loginSuccess,
  apiError,
  logoutUserSuccess,
  resetLoginFlag,
  updateUserSuccess,
  loading,
} from "./reducer";

import { setAuthorization } from "helpers/api_helper";

export const loginuser = (user: any, history: any) => async (dispatch: any) => {
  try {
    dispatch(loading());

    const response = await postJwtLogin({
      email: user.email,
      password: user.password,
      reCaptchaToken: user.reCaptchaToken,
    });

    setAuthorization(response.token);

    dispatch(loginSuccess(response));

    dispatch(loading());

    history("/");
  } catch (error) {
    dispatch(loading());

    console.log("erro ao fazer login", error);
    dispatch(apiError(error));
  }
};

export const updateUser =
  (user: any, history: any) => async (dispatch: any) => {
    try {
      dispatch(loading());

      await postUpdateUser({
        reCaptchaToken: user.reCaptchaToken,
        name: user.name,
        email: user.email,
        document: user.document,
        phone: user.phone,
        cep: user.cep,
        city: user.city,
        address: user.address,
        addressNumber: user.addressNumber,
        complement: user.complement,
        state: user.state,
        password: user.password,
        confirmPassword: user.confirmPassword,
      });

      dispatch(updateUserSuccess(user));

      dispatch(loading());

      history("/");
    } catch (error) {
      dispatch(loading());

      console.log("erro ao atualizar cadastro", error);
      dispatch(apiError(error));
    }
  };

export const logoutUser = (history: any) => async (dispatch: any) => {
  try {
    //deslogar no backend?
    dispatch(logoutUserSuccess(true));
    history("/");
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginMsgFlag = () => {
  try {
    const response = resetLoginFlag();
    return response;
  } catch (error) {
    return error;
  }
};

export const socialLogin =
  (type: any, history: any) => async (dispatch: any) => {
    try {
      let response: any;

      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const fireBaseBackend = getFirebaseBackend();
        response = fireBaseBackend.socialLoginUser(type);
      }

      const socialdata = await response;
      if (socialdata) {
        sessionStorage.setItem("authUser", JSON.stringify(socialdata));
        dispatch(loginSuccess(socialdata));
        history("/dashboard");
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };
