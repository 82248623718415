import React, { useState } from "react";

import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Card,
  CardBody,
} from "reactstrap";

import classnames from "classnames";

import Breadcrumb from "Components/Common/Breadcrumb";
import IndividualConfig from "Components/partnerCompany/configEmission/IndividualConfig";
import BatchConfig from "Components/partnerCompany/configEmission/BatchConfig";

import "./ConfigEmission.styles.scss";

const ConfigEmission = () => {
  //meta title
  document.title = "Configurar Emissão | Instituto Global ESG";

  const [activeTab, setactiveTab] = useState<any>("1");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Início"
            breadcrumbItem="Configurar Emissão de Vales"
          />

          <div className="configEmission">
            <Row className="w-100 justify-content-center">
              <Col lg={2} sm={3}>
                <Nav className="flex-column gap-2" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setactiveTab("1");
                      }}
                    >
                      <i className="mdi mdi-account-outline d-block check-nav-icon" />
                      <p className="fw-bold">Config. Individual</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        setactiveTab("2");
                      }}
                    >
                      <i className="mdi mdi-account-group" />

                      <p className="fw-bold">Config. em Lote</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg={8} sm={9} className="mt-4 mt-lg-0">
                <Card className="w-100 p-lg-4">
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <IndividualConfig />

                      <BatchConfig />
                    </TabContent>
                  </CardBody>
                </Card>
                {/* {activeTab !== "3" && (
                  <Row className="mt-4">
                    <div className="d-flex flex-lg-row flex-column gap-4">
                      <button className="btn btn-edit-perfil">Editar</button>
                      <button className="btn btn-save-perfil">Salvar</button>
                    </div>
                  </Row>
                )} */}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConfigEmission;
