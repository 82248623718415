import React from "react";
import { Link } from "react-router-dom";

import { Form, TabPane } from "reactstrap";

import BarCode from "./barcode_image.svg";

import "./firstTab.styles.scss";

const FirstTab = ({ wizardformik, toggleTab, activeTab }) => {
  return (
    <TabPane tabId={1}>
      <Form
        onSubmit={wizardformik.handleSubmit}
        className="first-tab final-client"
      >
        <div>
          <h2>
            Digite ou cole o <span>número</span> do código de barras da sua
            conta de energia:
          </h2>

          <p>
            Você encontra os números próximos ao código de barras do boleto.
          </p>

          <div className="container-barcode-input">
            <input
              placeholder="Digite aqui o Número do Código de Barras"
              type="text"
              name="barcode"
              value={wizardformik.values.barcode}
              onChange={wizardformik.handleChange}
              onBlur={wizardformik.handleBlur}
              className={`barcode-input ${
                wizardformik.errors.barcode && "invalid"
              }`}
            />

            {wizardformik.errors.barcode && (
              <i className="mdi mdi-information-outline icon-danger"></i>
            )}
          </div>

          {wizardformik.errors.barcode && (
            <span className="text-danger">{wizardformik.errors.barcode}</span>
          )}

          <img src={BarCode} alt="Imagem representando o Código de Barras" />
        </div>

        <div className="actions clearfix">
          <Link
            to="#"
            className={
              wizardformik.errors.barcode
                ? "button-continue disabled"
                : "button-continue"
            }
            onClick={() => {
              toggleTab(activeTab + 1);
            }}
          >
            Continuar
          </Link>
        </div>
      </Form>
    </TabPane>
  );
};

export default FirstTab;
