import {
  Row,
  Col,
  Table,
  Input,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  FormFeedback,
} from "reactstrap";

import QRCode from "./QRCode.png";

import Success from "./succes_2fa.svg";

import "./codeFA.styles.scss";

const CodeFA = () => {
  return (
    <TabPane tabId="3" id="v-pills-confir" role="tabpanel">
      <Card className="shadow-none border mb-0">
        <CardBody className="third-tab-perfil">
          <Row className="text-center align-items-center justify-content-center">
            <h3 className="title-tab">Autenticação 2FA</h3>
            <span className="describe mt-3">
              Escaneie o QR code abaixo no seu app autenticador:
            </span>
          </Row>

          <img src={QRCode} alt="PIX" className="mt-3" />

          <p className="describe-2fa">
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit.
          </p>

          <input className="input-code-2fa" type="text" />

          <button className="btn btn-save-perfil btn-active-2fa">Ativar</button>

          <div className="success-2fa">
            <h3 className="title-tab text-center mt-5">O 2FA está ativado.</h3>

            <img src={Success} alt="Success 2FA" className="mt-5" />

            <span className="describe mt-5 w-75 text-center">
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam in hendrerit urna. Pellentesque sit.
            </span>

            <button className="btn btn-alterate-2fa">Alterar 2FA</button>
          </div>
        </CardBody>
      </Card>
    </TabPane>
  );
};

export default CodeFA;
